import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const fetchEarningsData = (setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("earnings", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}


export const fetchBankDataAction = (formData, setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("get-bank-details", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })

        .catch((err) => {
            console.log(err)


        })
}

export const payOutRequestAction = (data, setLoader, loader, handleClose) => (dispatch, getState) => {

    commonAxios("payout-request", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                handleClose()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
                handleClose()

            }
            setLoader({
                ...loader,
                confirm: false
            })


        })
        .catch((err) => {
            console.log(err)



        })
}