import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IoSearchOutline } from 'react-icons/io5'
import { PiClockUser } from 'react-icons/pi'
import picPro from '../../../../images/pp.png';
import pinset from '../../../../images/icon-2/pinset.svg';
import CheckCircle from '../../../../images/icon-2/CheckCircle.svg';
import BackCircle from '../../../../images/icon-2/BackCircle.svg';
import XCircle from '../../../../images/icon-2/XCircle.svg';
import FilePdf from '../../../../images/icon-2/FilePdf.svg';
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FetchTaskUserSubmissionData, SubmissionTaskApprove, SubmissionTaskReject } from '../../../../Redux/Actions/TaskCreatorAction'
import ApproveModal from '../../../Tasker/ModalComponents/ApproveModal'
import { setAlert } from '../../../../Redux/Actions/AlertActions'
import RejectModal from '../../../Tasker/ModalComponents/RejectModal'
import { FaSpinner } from 'react-icons/fa'
import ReviewModal from '../../Reviews/ReviewModal'

const CreatorTaskSubmissions = () => {
    const [submissionsData, setSubmissionsData] = useState([])
    const [submissionsID, setSubmissionsID] = useState('')
    const [reason, setReason] = useState()
    const [modalShow, setModalShow] = useState(false)
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [sortOrder, setSortOrder] = useState('asc');
    const location = useLocation()
    const dispatch = useDispatch()

    const subSlugId = location.state.id.slug
    const [loader, setLoader] = useState({
        fetch: false,
        approve: false,
        reject: false
    })

    const handleModalShow = () => setModalShow(true)
    const handleModalFalse = () => setModalShow(false)

    const handleSort = () => {
        const sortedData = [...submissionsData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.createdat) - new Date(b.createdat);
            } else {
                return new Date(b.createdat) - new Date(a.createdat);
            }
        });
        setSubmissionsData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }
    const handleClose = () => setShow(false)

    const handleShow = () => setShow(true)
    const handleClose1 = () => setShow1(false)

    const handleShow1 = () => setShow1(true)

    const handleAccept = (id) => {
        handleShow();
        setSubmissionsID(id);
    };

    const handleRejects = (id) => {
        handleShow1();
        setSubmissionsID(id);
    };

    const handleConfirm = () => {
        if (submissionsID) {
            setLoader({
                ...loader,
                approve: true

            });
            const data = { id: submissionsID };
            dispatch(SubmissionTaskApprove(data, loader, setLoader, handleClose, handleModalShow));
        }
    };
    const handleReject = () => {
        if (submissionsID) {
            setLoader({
                ...loader,
                reject: true
            });
            const data = {
                id: submissionsID,
                "reason": reason

            };
            dispatch(SubmissionTaskReject(data, loader, setLoader, handleClose1));
        }
    };
    useEffect(() => {
        let SubData = {
            "slug": "eccbc87e4b5ce2fe28308fd9f2a7baf3"
        }
        setLoader({
            ...loader,
            fetch: true,

        })
        dispatch(FetchTaskUserSubmissionData(SubData, loader, setSubmissionsData, setLoader))
    }, [subSlugId])
    return (
        <>
            {
                loader.fetch ? <FaSpinner className='spin ms-1' style={{ fontSize: "40px", textAlign: "center", width: "100%", marginTop: "40px" }} /> :


                    <ScrollPanel className="custombar2 content-area pt-4">

                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='ms-3 mb-4'>
                                        <div className="table-bar">
                                            <div className="table-bar-left">
                                                <div className="table-bar-icon">
                                                    <div className="table-nav">
                                                        <div className="profile-menu">

                                                        </div>
                                                        <div className="profile-menu">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="successN" className="drop-btn">
                                                                    <img src={require("../../../../images/icon/filter.svg").default} alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet
                                                                    </li>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet.
                                                                    </li>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>


                                                    <ul>
                                                        <li title="Sort by Date">
                                                            <img src={require("../../../../images/icon/sort.svg").default} alt="" onClick={handleSort} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="table-bar-right">
                                                <div className="header-search">
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                    />
                                                    <span className="left-icon"><IoSearchOutline
                                                    /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="task_applications_wrap">
                                        <div className="row g-4">

                                            {submissionsData.length > 0 ? (
                                                submissionsData.map((data, index) => {
                                                    return (
                                                        <React.Fragment key={index}>

                                                            <div className="col-xxl-4 col-lg-6 col-12" >
                                                                <div className='account-block' style={{ height: "330px" }}>
                                                                    <div className="Submissions_details">
                                                                        <div className="application_date mb-0">
                                                                            <PiClockUser />
                                                                            <span>{data.createdat}</span>
                                                                        </div>

                                                                        <div className="application_img">
                                                                            <img src={data.profile} alt={data.name} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="application_para">
                                                                        <h3>{data.name}</h3>
                                                                        <h3>{data.title}</h3>
                                                                        <p>{data.text || "No description available"}</p>
                                                                        <div className="sub_links">

                                                                            {data.link.length > 0 ? (
                                                                                data.link.map((url, Index) => (
                                                                                    <React.Fragment key={Index}>

                                                                                        <div className='d-flex'>
                                                                                            <span style={{ fontWeight: 700, color: "#000" }}> Link {Index + 1} : </span>
                                                                                            <a
                                                                                                href={url}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                {url}
                                                                                            </a>
                                                                                        </div>


                                                                                    </React.Fragment>
                                                                                ))
                                                                            ) : (
                                                                                <p className='mt-2 ms-3'>No links available</p>
                                                                            )}
                                                                        </div>
                                                                        <div className="sub_links mt-0">

                                                                            {data.files.length > 0 ? (
                                                                                data.files.map((urls, Index) => (
                                                                                    <React.Fragment key={Index}>

                                                                                        <div className='d-flex'>
                                                                                            <span style={{ fontWeight: 700, color: "#000" }}> File {Index + 1} : </span>
                                                                                            <a
                                                                                                href={urls}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                {urls}
                                                                                            </a>
                                                                                        </div>


                                                                                    </React.Fragment>
                                                                                ))
                                                                            ) : (
                                                                                <p className='mt-2 ms-3'>No links available</p>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="Submissions_info">
                                                                        <div className="total_sub_action">
                                                                            <img src={CheckCircle} alt="Check Circle" onClick={() => handleAccept(data.id)} style={{ cursor: "pointer" }} />
                                                                            <img src={XCircle} alt="X Circle" onClick={() => handleRejects(data.id)} style={{ cursor: "pointer" }} />
                                                                            {/* <img src={BackCircle} alt="Back Circle" /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                                )
                                            ) : (
                                                <p style={{ textAlign: "center" }}>No submissions available</p>
                                            )}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollPanel >
            }
            <ApproveModal
                show={show}
                handleClose={handleClose}
                loader={loader}
                handleConfirm={handleConfirm}
            />
            <RejectModal
                show={show1}
                handleClose={handleClose1}
                handleReject={handleReject}
                setReason={setReason}
                loader={loader}
                reason={reason}
            />

            <ReviewModal
                handleModalFalse={handleModalFalse}
                handleModalShow={modalShow}
                id={submissionsID}
            />
        </>
    )
}

export default CreatorTaskSubmissions
