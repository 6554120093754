import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { AiOutlineDollarCircle } from 'react-icons/ai'
import { GoDotFill } from 'react-icons/go'
import { IoSearchOutline } from 'react-icons/io5'
import Pagination from '../../Common/Pagination'
import { FaSpinner } from 'react-icons/fa'
import AdminAPproveModal from '../AdminModal/AdminApproveModal'
import AdminRejectModal from '../AdminModal/AdminRejectModal'

const ApprovePayout = (props) => {
    const [searchInput, setSearchInput] = useState('')

    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [data, setData] = useState([])
     const[producatId,setProducatId]=useState('')
     const[showModel,setShowModel]=useState(false)
     const handleShow=()=>setShowModel(true)
     const handleClose=()=>setShowModel(false)
     const[showModel1,setShowModel1]=useState(false)
     const handleShow1=()=>setShowModel1(true)
     const handleClose1=()=>setShowModel1(false)
    const handlesort = () => {

        const sortedData = [...filteredData].sort((a, b) => {
            const dateA = new Date(a.withdraw_date);
            const dateB = new Date(b.withdraw_date);

            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {

        if (data) {
            if (searchInput) {
                
                const newFilteredData = data.filter(val =>
                    val.name.toLowerCase().includes(searchInput.toLowerCase())||
                    String(val.amount || '').includes(searchInput.toLowerCase())||
                    String(val.id || '').includes(searchInput.toLowerCase())
                );

                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data);
            }
        }
    }, [searchInput, data]);

    useEffect(() => {
        if (props.data) {
            setData(props.data)
        } else {
            setData([])
        }
    }, [props.data])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);
const handleAccept=(pId)=>{

handleShow()
setProducatId(pId)
}
const handleDecline=(pId)=>{
    handleShow1()
    setProducatId(pId)
}

    return (
        <>
        {
             props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
        
            <ScrollPanel className="custombar2 content-area pt-4">
                <div className="main_task_details ps-4 pe-4 pb-4">
                    <div className="do_task_wrapper ">
                        <div className="row">
                            <div className="col-12">
                                <div className="account-block mt-2">
                                    <h6>Payouts</h6>
                                    <div className="d-flex task_main_wrap">
                                        <div className="task_process">
                                            <span>Total Payouts</span>

                                            <div className="progress_wrap" style={{ position: "relative" }}>
                                                <h3 style={{ fontWeight: "700" }}>{props?.payout?.totalpayout}₹</h3>
                                            </div>
                                        </div>
                                        <div className="task_process ps-3 pe-3">
                                            <span className="text-center w-100">Approved</span>
                                            <div className="task_process_bar text-center">
                                                <h3>{props?.payout?.totalapproved}</h3>
                                            </div>
                                        </div>
                                        <div className="task_process ps-3 pe-3">
                                            <span className="text-center w-100">Approval Pending</span>
                                            <div className="task_process_bar text-center">
                                                <h3>{props?.payout?.totalpending}</h3>
                                            </div>
                                        </div>
                                        {/* <div className="task_process ps-3 pe-3 ">
                                            <span className="text-center w-100">Scheduled</span>
                                            <div className="task_process_bar text-center">
                                                <h3>$1,550</h3>
                                            </div>
                                        </div> */}
                                        <div className="task_process ps-3 pe-3 " style={{ border: "0" }}>
                                            <span className="text-center w-100">Rejected</span>
                                            <div className="task_process_bar text-center">
                                                <h3>{props?.payout?.totalrejected}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-bar">
                                    <div className="table-bar-left">
                                        <div className="table-bar-icon">
                                            <div className="table-nav">
                                                <div className="profile-menu">

                                                </div>
                                                <div className="profile-menu">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="successN" className="drop-btn">
                                                            <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                            <li className="drop-list">
                                                                <span className="custom-check">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="agentType"
                                                                    />
                                                                    <span className="checkMark"></span>
                                                                </span>
                                                                dolor sit amet
                                                            </li>
                                                            <li className="drop-list">
                                                                <span className="custom-check">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="agentType"
                                                                    />
                                                                    <span className="checkMark"></span>
                                                                </span>
                                                                dolor sit amet.
                                                            </li>


                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>


                                            <ul>
                                                <li title="Sort by Date" onClick={handlesort}>
                                                    <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="table-bar-right">
                                        <div className="header-search">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                name="searchInput"
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            />
                                            <span className="left-icon"><IoSearchOutline
                                            /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row mt-1 g-4">
                        {
                            currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                                const bankDetails = JSON.parse(curElem.bank_details)
                                
                                return (
                                    <div className="col-xl-4 col-lg-6 col-12" key={i}>
                                        <div className='account-block approve-payout'>
                                            <div className='account-header'>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <AiOutlineDollarCircle className='dollar-icon' />
                                                    <h6 style={{ marginRight: "auto", fontSize: "14px" }}>Adi’s Withdraw Request</h6>
                                                </div>
                                                <span className='timing'> <GoDotFill style={{ color: "#9FA1A2", fontSize: "10px" }} />
                                                    5 Hours ago</span>
                                            </div>
                                            <ul>
                                                <li>
                                                    <span>Available Balance</span>
                                                    <span>₹{curElem.balance}</span>
                                                </li>
                                                <li>
                                                    <span>Withdraw Amounte</span>
                                                    <span>₹{curElem.amount}</span>
                                                </li>
                                                <li>
                                                    <span>Withdrawl Method</span>
                                                    <span>Bank Transfer:  {bankDetails.account_no}</span>
                                                </li>
                                                <li>
                                                    <span>Withdrawl Fee</span>
                                                    <span>₹0.00</span>
                                                </li>

                                            </ul>
                                            <div className="application_btn mt-2">
                                                <button className='theme-btn blue' type="button" onClick={()=>handleAccept(curElem.id)}>Accept Application</button>
                                                <button className='theme-btn indigo' type="button" onClick={()=>handleDecline(curElem.id )}>Decline Request</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <div className='d-flex align-items-center justify-content-center'> No Found History</div>
                        }

                    </div>
                    <Pagination

                        listData={filteredData}
                        pagination={pagination}
                        setPagination={setPagination}
                        currentTodo={currentTodo}
                        listArr={data}
                        loader={props.loader.fetch}
                    />


                </div>

             <AdminAPproveModal show={showModel} handleClose={handleClose} producatId={producatId} type="payout"/> 
              <AdminRejectModal show={showModel1} handleClose={handleClose1} producatId={producatId} type="payout"/>
            </ScrollPanel>
}
        </>
    )
}

export default ApprovePayout
