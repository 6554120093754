import React, { useEffect, useState } from "react";

import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoCalendarClearOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';
import Swal from "sweetalert2";
import { FaSpinner } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { changeProfile, loadUser, logoutAllDevice, userInformation } from "../../../Redux/Actions/AuthActions";
import { fetchData } from "../../../Redux/Actions/CommonActions";
import { Calendar } from 'primereact/calendar';
import Locationget from "../../Common/Locationget";

function PersonalDetailsTabs() {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [options, setOptions] = useState([]); // State for options

  const [editInfo, setEditInfo] = useState(auth?.user)
  const [loader, setLoader] = useState({
    user: false,
    image: false,
    save:false,
  })
  const [selectedValues, setSelectedValues] = useState(JSON.parse(auth?.user?.language));
  useEffect(() => {
    dispatch(fetchData("get_languages", {}, (data) => {
      setOptions(data.map(lang => ({ label: lang.name, value: lang.id })));
    }, loader, setLoader, false, "shortDataLg"));
    dispatch(loadUser())
  }, [dispatch])

  const handleChange = (e) => {
    setSelectedValues(e.value);


  };
  const onImageChange = (e) => {
    if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
      if (e.target.files[0].size < 5000000) {
        const formData = new FormData()
        formData.append('user_id', auth.user.id);
        formData.append('image', e.target.files[0]);
        setLoader({
          ...loader,
          image: true
        })
        dispatch(changeProfile(formData, loader, setLoader))

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Max allowed size is 5MB!',
          confirmButtonColor: "#000"
        })
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You have selected invalid file type!',
        confirmButtonColor: "#000"
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let formData = {
      ...editInfo,
      language: selectedValues
    }
setLoader({
  ...loader,
  save:true
})
    dispatch(userInformation(formData, setLoader,loader))

  }
  const handleAllAccountLogOut = () => {

    dispatch(logoutAllDevice())
  }
  return (
    <>
      <div className="account-block relative">

        {

          loader.image ?
            <div className="accountPic">
              <div className="d-flex align-items-center justify-content-center" style={{ height: "100%", fontSize: 20 }}>
                <FaSpinner className="spin" />
              </div>
            </div>
            :
            <>
              <div className="accountPic">
                <img src={auth.user.profile || "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg"} alt="" />
              </div>
              <label htmlFor="upload-img" className="account-edit-btn">
                <input type="file" name="" id="upload-img" onChange={onImageChange} />
                <BiEditAlt />
              </label>
            </>
        }

        {/* <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg" alt="" /> */}

        {/* <span className="account-edit-btn">
              
              <input type="file" name="" id="" onChange={onImageChange}/>
              <BiEditAlt />
              </span> */}

        <div className="contInfo">
          <h5>{auth?.user?.name ? auth?.user?.name : "-"}</h5>
          <ul>



            <li><HiOutlineEnvelope /> <span>{auth?.user?.email ? auth?.user?.email : "-"}</span></li>
          </ul>
        </div>
        <div className="contStat">
          <ul>
            <li>
              <h6>Profile Compleation</h6>
              <div className="progressBar mt-2">
                <span className="progressBar-mark" style={{ width: auth?.user?.profile_compleation }}></span>
                <span className="progressBar-num">{auth?.user?.profile_compleation}%</span>
              </div>
            </li>
            <li>
              <h6>Earnings</h6>
              <h5>{auth?.user?.earning ? auth?.user?.earning : "-"}</h5>
            </li>
            <li>
              <h6>Tasks</h6>
              <h5>{auth?.user?.task}</h5>
            </li>
            <li>
              <h6>Success Rate</h6>
              <h5>{auth?.user?.success_rate}%</h5>
            </li>
          </ul>
        </div>
      </div>
      <div className="account-block mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <h6>Profile Details</h6>
          <a onClick={handleShow} style={{ cursor: "pointer" }}>Edit Profile</a>
        </div>
        <div className="profileDetails">
          <ul>
            <li><span>Name</span><span>{auth?.user?.name ? auth?.user?.name : "-"}</span></li>
            <li><span>Contact Phone <span className="tooltipN"><AiOutlineExclamationCircle /><span className="tooltiptext">Tooltip text</span></span></span><span>{auth?.user?.mobileNumber}</span><span className="verBadge">Verified</span></li>
            <li><span>Email</span><span>{auth?.user?.email}</span></li>

            <li>
              <span>Language</span>
              <span>{auth.user.language_name ? auth.user.language_name.map((curElem, i) => {

                return (
                  <span>{curElem?.name}</span>
                )
              }) : "-"}</span></li>
            <li><span>Age</span><span>{auth?.user?.age ? auth?.user?.age : "-"}</span></li>
          </ul>
        </div>
        <div className="alert-box dark mt-5">
          <div className="alert-icon"><AiOutlineExclamationCircle /></div>
          <div className="alert-txt">
            <h6>We need your attention!</h6>
            <p>You don’t have any payment method added yet. To start receiving withdrawls, please <a href="">Add Payment Method</a>.</p>
          </div>
        </div>
      </div>
      <div className="account-block mt-4">
        <h6 className="pb-3">Support</h6>
        <div className="supportBlock" onClick={handleAllAccountLogOut}>
          <h6>Log out of all devices</h6>
          <p>Log out of all other active sessions on other devices besides this one.</p>
        </div>
        <div className="supportBlock">
          <h6 className="txt-red">Delete my account</h6>
          <p>Permanently delete the account and remove access from all devices.</p>
        </div>
      </div>

      <Modal className="modalRight" show={show} onHide={handleClose}>
        <Modal.Body>

          <div className='modalTitle'>
            <h6>Edit Profile Details</h6>
          </div>

          <ScrollPanel className="modalScroll">
            <form onSubmit={handleSubmit}>
              <h6 className='pt-4'>{editInfo.name}</h6>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Name</label>
                <input className="input" type="text" name="name" id="" value={editInfo.name} onChange={(e) => setEditInfo({ ...editInfo, "name": e.target.value })} />
              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Gender</label>
                <input className="input" type="text" name="gender" id="" value={editInfo.gender} onChange={(e) => setEditInfo({ ...editInfo, "gender": e.target.value })} />
              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">age</label>
                <input className="input" type="tel" name="age" id="" value={editInfo.age} onChange={(e) => setEditInfo({ ...editInfo, "age": e.target.value })} />
              </div>

              <div className="input-wrap alt">
                <label className="label" htmlFor="">Date of Birth</label>
                {/* <div className='inp-with-icon'>
                  <input className="input" type="text" name="dob" id="" value={editInfo.dob} onChange={(e) => setEditInfo({ ...editInfo, "dob": e.target.value })} />
                  <span className='inp-icon'><IoCalendarClearOutline /></span>
                </div> */}
                <div className="flex-auto">
                  {/* <label htmlFor="buttondisplay" className="font-bold block mb-2">
                    Button Display
                </label> */}
                  <Calendar id="buttondisplay" value={editInfo.dob} onChange={(e) => setEditInfo({ ...editInfo, "dob": e.target.value })} showIcon />


                </div>
              </div>

              <div className="input-wrap alt mb-4">
                <MultiSelect
                  value={selectedValues}
                  options={options}
                  onChange={handleChange}
                  optionLabel="label"
                  placeholder="Select Language"
                />
              </div>

              <div className="modal-button-bottom">
                <button className="theme-btn bdr" type="button" onClick={handleClose}>Cancel</button>
                <button className="theme-btn light" type="submit">{loader.save?<FaSpinner className="spin ms-1"/>:""}Save</button>
              </div>
            </form>
          </ScrollPanel>

        </Modal.Body>
      </Modal>

    </>
  )
}

export default PersonalDetailsTabs;