import React, { useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { FaSpider, FaSpinner } from "react-icons/fa";
import BankModal from "../ModalComponents/BankModal";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import {  DeleteBankDetail, UpdateUpiVerify } from "../../../Redux/Actions/PaymentActions";
import { GoDotFill } from "react-icons/go";
import EditBankModel from "../ModalComponents/EditBankModel";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";

function PaymentMethodsTabs() {
  const dispatch = useDispatch()
  const auth=useSelector(state=>state.auth.user)
  
  const [upiId, setUpiId] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [loader, setLoader] = useState(false)
  const[edit,setEdit]=useState("")
const FetchBankDetail=useSelector(state=>state.BankDetails.data)

  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const upiPattern = /^[a-zA-Z0-9._]{3,50}@[a-zA-Z0-9._]{2,50}$/;
  const handleUpiVerify = () => {
    
    if (upiPattern.test(upiId) === false) {
// setLoader(true)
      dispatch(setAlert("Correct Upi ", "danger"))
    }
    else {
      let fromdata = {
        "upi": upiId
      }
      setLoader(true)
      setUpiId("")
      dispatch(UpdateUpiVerify(fromdata, setLoader))
    
    }

  }
  const handleEditdata=(curElem)=>{
    handleShow1()
   
    setEdit(curElem)
  }
  const handleDelete=(pId)=>{
console.log(pId)
let formdata={
  "id":pId
}
dispatch(DeleteBankDetail(formdata,setLoader))
  }
  return (
    <>
      <div className="account-block">
        <h6>Select You Payment Method</h6>
        <div className="block-white mt-4">
          {/* <div className="payment-method-single">
            <div className="payment-method-img"><img src={require("../../../images/stripe.svg").default} alt="" /></div>
            <div className="payment-method-txt">
              <h6>Stripe</h6>
              <p>Connect your Stripe account.</p>
            </div>
          </div> */}
          <div className="payment-method-single mt-4">
            <div className="payment-method-img"><img src={require("../../../images/paypal.svg").default} alt="" /></div>
            <div className="payment-method-txt">
              <h6>PayPal</h6>
              <p>Connect your PayPal account.</p>
            </div>
          </div>
          <div className="payment-method-single mt-4" style={{ cursor: "pointer" }}>
            <div className="payment-method-img"><img src={require("../../../images/credit.svg").default} alt="" /></div>
            <div className="payment-method-txt" onClick={handleShow}>
              <h6>Bank card</h6>
              <p>Add a Bank card.</p>
            </div>
          </div>
          <div className="payment-method-single mt-4">
            <div className="payment-method-img"><img src={require("../../../images/credit.svg").default} alt="" /></div>
            <div className="payment-method-txt">
              <h6>UPI</h6>
              <p>Add UPI Id</p>
              {auth?.upi?
               <div className="mt-2">
               <p><b>Upi Id:</b> {auth?.upi}</p>
               <p><b>Name:</b> {auth?.upi_name}</p>
             </div>
              :""}
             
              <div className="d-flex gap-2 mt-3">
                <input type="text" className="form-control w-50" id="upiId" name="" value={upiId} placeholder="Myname@Kotak" onChange={(e) => setUpiId(e.target.value)} />
                <button onClick={handleUpiVerify} className="theme-btn" type="submit" style={{ cursor: "pointer" }}>Verify {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
              </div>
             

            </div>
          </div>
        </div>
      <div className="col-12">
        <div className="campaign-stat-right w-100 ps-0 pe-0">
          <div className="account-block bank_details">
            <div className="d-flex justify-content-between">
              <h6>Bank Details List</h6>
              {/* <a href=""><span>Show All</span></a> */}
            </div>
            <div className="table-responsive mt-3">
              <table className="table theme-table">
                <thead>
                  <tr>
                    <th>Acc. Holder Name</th>
                    <th>Account Number</th>
                    <th>IFSC Code</th>
                    <th>Bank Name</th>
                    <th>Branch Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {FetchBankDetail.length>0?FetchBankDetail.map((curElem,i)=>{
                  
                    return(
                      <tr key={i}>
                      <td data-label="Acc. Holder Name">{curElem?.account_holder_name }</td>
                      <td data-label="Account Number">{curElem?.account_no}</td>
                      <td data-label="IFSC Code">{curElem?.ifsc_code}</td>
                      <td data-label="Bank Name">{curElem?.bank_name}</td>
                      <td data-label="Branch Name">{curElem?.branch_name}</td>
                      <td data-label="Status">
                        {
                          curElem?.is_primary==="0"?"-":

                        
                        <p className="Status_complete"><GoDotFill className="Status_complete" style={{ marginRight: "3px" }} />Primary</p>
                        }
                        </td>
                      
                      <td data-label="Action">
                      <BiEditAlt className="me-" onClick={()=>handleEditdata(curElem)} style={{cursor:"pointer",fontSize:"16px"}}/>
                      <MdDeleteOutline style={{cursor:"pointer",fontSize:"16px"}} onClick={()=>handleDelete(curElem.id)}/>
                      </td>
                    </tr>
                    )
                  }):<td className="text-center pt-3" colSpan={7}>No Bank Details</td>}
                 
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div></div>

      {/* table */}
      
      <BankModal 
      show={show}
        handleClose={handleClose}
      />
    <EditBankModel
     show1={show1}
     handleClose1={handleClose1}
     edit={edit}
    />
    </>
  )
}

export default PaymentMethodsTabs;