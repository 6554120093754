import React from "react";
import { Link } from "react-router-dom";

const DashboardFooter = () => {

    return (
        <>
            <div className="dashboard-footer">
                <div className="dashboard-footer-left">
                    <p className="text-center">© 2024, All Rights Reserved to <a target="_blank" href="https://vineasx.com/">VineaSX Solutions LLC</a>. Developed and Maintained by <a target="_blank" href="http://www.vega6.com/">Vega6</a></p>
                </div>
                <div className="dashboard-footer-right">
                    <div className="footer-link">
                        <ul>
                            <li><a href="#" target="_blank">About</a></li>
                            <li><a href="#" target="_blank">Support</a></li>
                            <li><a href="#" target="_blank">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardFooter;