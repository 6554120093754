import React, { useState } from 'react';
import { IoCalendarClearOutline } from "react-icons/io5";
import { FiDownload, FiChevronLeft, FiChevronRight } from "react-icons/fi";

function MySpending() {

   return (
      <>
       <div className='account-block relative'>
          <h5>Spending</h5>
          <div className='contStat'>
            <ul>
                <li>
                    <h6>Net Spending</h6>
                    <h5>$6,840</h5>
                </li>
                <li>
                    <h6>Billed</h6>
                    <h5>$4,530</h5>
                </li>
                <li>
                    <h6>Upcoming</h6>
                    <h5>$2,600</h5>
                </li>
                <li>
                    <h6>Platform Fee ( 5% )</h6>
                    <h5>$550</h5>
                </li>
            </ul>
          </div>
          <hr className='mb-0' />
       </div>

       <div className='account-block mt-4'>
        <div className='d-flex align-items-center justify-content-between'>
           <h6>All Spending</h6>
           <div className='input-wrap simple'>
            <select className='input' name="" id="">
                <option value="">This Week</option>
                <option value="">This Week</option>
                <option value="">This Week</option>
            </select>
           </div>
        </div>

        <div className='table-responsive mt-4'>
            <table className='table theme-table'>
                <tr>
                    <th>Task ID</th>
                    <th>Task Title</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th className='text-end'>Invoice</th>
                </tr>
                <tr>
                    <td>678935899</td>
                    <td>Share My Reels On Instagram</td>
                    <td><IoCalendarClearOutline /> Feb 25, 2023</td>
                    <td>$123.79</td>
                    <td><span className='table-stat active'>Complete</span></td>
                    <td className='text-end'><a href="">PDF <FiDownload /></a></td>
                </tr>
                <tr>
                    <td>678935899</td>
                    <td>Share My Reels On Instagram</td>
                    <td><IoCalendarClearOutline /> Feb 25, 2023</td>
                    <td>$123.79</td>
                    <td><span className='table-stat active'>Complete</span></td>
                    <td className='text-end'><a href="">PDF <FiDownload /></a></td>
                </tr>
                <tr>
                    <td>678935899</td>
                    <td>Share My Reels On Instagram</td>
                    <td><IoCalendarClearOutline /> Feb 25, 2023</td>
                    <td>$123.79</td>
                    <td><span className='table-stat active'>Complete</span></td>
                    <td className='text-end'><a href="">PDF <FiDownload /></a></td>
                </tr>
                <tr>
                    <td>678935899</td>
                    <td>Share My Reels On Instagram</td>
                    <td><IoCalendarClearOutline /> Feb 25, 2023</td>
                    <td>$123.79</td>
                    <td><span className='table-stat active'>Complete</span></td>
                    <td className='text-end'><a href="">PDF <FiDownload /></a></td>
                </tr>
                <tr>
                    <td>678935899</td>
                    <td>Share My Reels On Instagram</td>
                    <td><IoCalendarClearOutline /> Feb 25, 2023</td>
                    <td>$123.79</td>
                    <td><span className='table-stat active'>Complete</span></td>
                    <td className='text-end'><a href="">PDF <FiDownload /></a></td>
                </tr>
            </table>
        </div>
       </div>

       <div className='table-paging'>
            <ul>
                <li><FiChevronLeft /></li>
                <li className='active'>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li><FiChevronRight /></li>
            </ul>
        </div>
      </>
   )
}
export default MySpending;