import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import SidePanelAdmin from '../../Common/SidePanelAdmin'
import DashboardHeader from '../../Common/DashboardHeader'
import ApprovePayout from './ApprovePayout'
import AdminPayoutHistory from './AdminPayoutHistory'
import { FetchAdminPayOut } from '../../../Redux/Actions/AdminPayOutAction'
import { useDispatch } from 'react-redux'
import TitleBar from '../../Common/TitleBar'

const ApproveNavPayout = () => {
    const dispatch=useDispatch()
    const[loader,setLoader]=useState({
        fetch:true
    })
    const[adminPayData,setAdminPayPayData]=useState([])
    const fetchdata=()=>{
            dispatch(FetchAdminPayOut(loader,setLoader,setAdminPayPayData))
    }
    useEffect(()=>{
        fetchdata()
    },[])
    
    return (
        <>
 <TitleBar title="Payout" />
            <div className="dashboard creator_new_task container-fluid overflow-hidden">
                <div className="row">

                    <div className="col-lg-2 col-md-3 col-sm-4 col-12 pe-0">
                        <SidePanelAdmin />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 pt-0 pb-0 ps-0">
                        <div className="dashboard-in  ">
                            <DashboardHeader />
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" style={{ padding: "20px 28px 0 28px" }} >
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" >Approve Payout</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Payout History</Nav.Link>
                                            </Nav.Item>


                                        </Nav>


                                        <div className="setting_right_side_wrap">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <ApprovePayout data={adminPayData.pending_request} loader={loader} payout={adminPayData.payout} fetchdata={fetchdata}/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <AdminPayoutHistory data={adminPayData.history} loader={loader}/>

                                                </Tab.Pane>


                                            </Tab.Content>
                                        </div>
                                    </Tab.Container >

                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div >

        </>
    )
}

export default ApproveNavPayout
