import React, { useState } from "react";

import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoCalendarClearOutline } from "react-icons/io5";

import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useDispatch,useSelector } from "react-redux";
import { AddQulification } from "../../../Redux/Actions/AuthActions";
import { Calendar } from 'primereact/calendar';

function QualificationExperienceTabs() {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [loader,setLoader]=useState(false)
  // console.log("auth",auth.user)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
const[qualification,setQualification]=useState(auth?.user)
    const [selectedValues, setSelectedValues] = useState(null);
    const options = [
        { label: 'Option 1', value: '1' },
        { label: 'Option 2', value: '2' },
        { label: 'Option 3', value: '3' },
        { label: 'Option 4', value: '4' }
    ];
    const handleChange = (e) => {
        setSelectedValues(e.value);
    };
const handleChangeInput=(e)=>{
const{name,value}=e.target;
setQualification({...qualification,[name]:value})
}
const handleSubmit=(e)=>{
  e.preventDefault()
// console.log("qualification",qualification)

dispatch(AddQulification(qualification,setLoader))
}
    return(
        <>
          <div className="account-block">
              <div className="d-flex align-items-center justify-content-between">
                  <h6>Educational Background</h6>
                  <a onClick={handleShow} style={{cursor:"pointer"}}>Edit Qualification</a>
              </div>
              <div className="profileDetails">
                <ul>
                    <li><span>Name of School</span><span>{auth?.user?.school_name?auth?.user?.school_name:"-"}</span></li>
                    <li><span>Degree</span><span>{auth?.user?.degree?auth?.user?.degree:"-"}</span></li>
                    <li><span>Start Date</span><span>{auth?.user?.start_date?auth?.user?.start_date:"-"}</span></li>
                    <li><span>End Date</span><span>{auth?.user?.end_date?auth?.user?.end_date:"-"}</span></li>

                    <li><span>Grade</span><span>{auth?.user?.grade?auth?.user?.grade:"-"}</span></li>
                </ul>
            </div>

            <hr className="mt-4 mb-4" />
            <h6>Work Experience</h6>
            <div className="profileDetails">
                <ul>
                    <li><span>Company Name</span><span>{auth?.user?.company?auth?.user?.company:"-"}</span></li>
                    <li><span>Designation</span><span>{auth?.user?.designation?auth?.user?.designation:"-"}</span></li>
                    <li><span>Employment Period</span><span>{auth?.user?.period?auth?.user?.period:"-"}</span></li>
                </ul>
            </div>
          </div>


          <Modal className="modalRight" show={show} onHide={handleClose}>
          <Modal.Body>

          <div className='modalTitle'>
                <h6>Edit Qualification</h6>
           </div>

          <ScrollPanel className="modalScroll">
               <form onSubmit={handleSubmit}>
              <h6 className='pt-4'>Name of School/College/University</h6>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Name of School/College/University</label>
                <input className="input" type="text" name="school_name" id="" value={qualification.school_name} onChange={(e) => setQualification({ ...qualification, "school_name": e.target.value })}/>
              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Degree</label>
                <input className="input" type="text" name="degree" id="" value={qualification.degree} onChange={(e) => setQualification({ ...qualification, "degree": e.target.value })}  />
              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Start Date</label>
                {/* <div className='inp-with-icon'>
                   <input className="input" type="text" name="start_date" id="" value={qualification.start_date} onChange={(e) => setQualification({ ...qualification, "start_date": e.target.value })}/>
                   <span className='inp-icon'><IoCalendarClearOutline /></span>
                </div> */}
                <Calendar id="buttondisplay" name="start_date"  value={qualification.start_date} onChange={(e) => setQualification({ ...qualification, "start_date": e.target.value })} showIcon />

              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">End Date</label>
                {/* <div className='inp-with-icon'>
                   <input className="input" type="text" name="start_date" id="" value={qualification.end_date} onChange={(e) => setQualification({ ...qualification, "end_date": e.target.value })}/>
                   <span className='inp-icon'><IoCalendarClearOutline /></span>
                </div> */}
                <Calendar id="buttondisplay" name="end_date"  value={qualification.end_date} onChange={(e) => setQualification({ ...qualification, "end_date": e.target.value })} showIcon />

              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Grade</label>
                <input className="input" type="text" name="grade" id=""  value={qualification.grade} onChange={(e) => setQualification({ ...qualification, "grade": e.target.value })}/>
              </div>
              
              <h6 className='pt-4'>Work Experience</h6>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Company Name</label>
                <input className="input" type="text" name="company" id="" value={qualification.company} onChange={(e) => setQualification({ ...qualification, "company": e.target.value })} />
              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Designation</label>
                <input className="input" type="text" name="designation" id="" value={qualification.designation} onChange={(e) => setQualification({ ...qualification, "designation": e.target.value })} />
              </div>
              <div className="input-wrap alt mb-5">
                <label className="label" htmlFor="">Employment Period</label>
                <div className='inp-with-icon'>
                   <input className="input" type="text" name="period" id="" value={qualification.period} onChange={(e) => setQualification({ ...qualification, "period": e.target.value })} />
                   <span className='inp-icon'><IoCalendarClearOutline /></span>
                </div>
              </div>
              

              <div className="modal-button-bottom">
                <button className="theme-btn bdr" onClick={handleClose}>Cancel</button>
                <button className="theme-btn light" type="submit">Save</button>
              </div>
</form>
          </ScrollPanel>
             
          </Modal.Body>
        </Modal>
        </>
    )
}

export default QualificationExperienceTabs;