import React, { useState } from 'react';
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import AdminTaskList from './AdminTaskList';
import AdminCreatorList from './AdminCreatorList';
import TitleBar from '../../Common/TitleBar';



const MangerUser = () => {
  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const [isActive2, setIsActive2] = useState(false);
  const toggleNotification = () => {
    setIsActive2(!isActive2);


  };


  return (
    <>
     <TitleBar title="Users" />
    <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
    <SidePanelAdmin />

       <div className="dashboard-in">
       <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} />
          <ScrollPanel className="custombar2 content-area">
             <div className="content-area-in tab-style">
                <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                   <div className="tab-bar">
                      <Nav variant="pillsb">
                         <Nav.Item><Nav.Link eventKey="tab-1">Tasker List</Nav.Link></Nav.Item>
                         <Nav.Item><Nav.Link eventKey="tab-2">Creator List</Nav.Link></Nav.Item>
                         
                      </Nav>

                     
                   </div>
                   <Tab.Content>
                      <Tab.Pane eventKey="tab-1">
                        <AdminTaskList/>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab-2">
                         <AdminCreatorList />
                      </Tab.Pane>
                     
                      
                      
                      
                   </Tab.Content>
                </Tab.Container>
             </div>
          </ScrollPanel>
          <DashboardFooter />
       </div>
       
    </div>

 </>
  )
}

export default MangerUser