import React from 'react'
import CreatorTaskDetails from './CreatorTaskDetails'
import DashboardHeader from '../../../Common/DashboardHeader'
import { Nav, Tab } from 'react-bootstrap'
import CreatorTaskApplications from './CreatorTaskApplications'
import CreatorTaskSubmissions from './CreatorTaskSubmissions'
import CreatorTaskReviews from './CreatorTaskReviews'
import SidePanelTaskCreator from '../../../Common/SidePanelTaskCreator'
import CreateNewTask from '../CreateNewTask'

const CreatorNavDetails = () => {
    return (
        <>
            <div className="dashboard creator_new_task container-fluid overflow-hidden">
                <div className="row">

                    <div className="col-lg-2 col-md-3 col-sm-4 col-12 pe-0">
                        <SidePanelTaskCreator />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 pt-0 pb-0 ps-0">
                        <div className="dashboard-in  ">

                            <DashboardHeader />
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" style={{ padding: "20px 28px 0 28px" }} >
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" >Task Details</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="second">Applications</Nav.Link>
                                            </Nav.Item> */}
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Submissions</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="fourth">Reviews</Nav.Link>
                                            </Nav.Item> */}

                                        </Nav>


                                        <div className="setting_right_side_wrap">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    {/* <CreatorTaskDetails /> */}
                                                    <CreateNewTask />
                                                </Tab.Pane>
                                                {/* <Tab.Pane eventKey="second">
                                                    <CreatorTaskApplications />
                                                </Tab.Pane> */}
                                                <Tab.Pane eventKey="third">
                                                    <CreatorTaskSubmissions />
                                                </Tab.Pane>
                                                {/* <Tab.Pane eventKey="fourth">
                                                    <CreatorTaskReviews />
                                                </Tab.Pane> */}

                                            </Tab.Content>
                                        </div>
                                    </Tab.Container >

                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div >
        </>
    )
}

export default CreatorNavDetails
