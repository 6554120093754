import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
export const FetchAdminManager = (loader,setLoader,setData) => (dispatch, getState) => {

    commonAxios("/admin-fetch-task", {}, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
               setData(res.data)
               
               } else {
                console.log(res.msg)
                
            }
            setLoader({
                ...loader,
                fetch:false,
            })
        }).catch((err) => {
         console.log(err)
           
        })
}

export const adminMangerApprovedAction = (data,setLoader,handleClose) => (dispatch, getState) => {

    commonAxios("admin-approve-task", data, dispatch,getState().auth.token)
        .then((res) => {
            
            if (res.status) {
              
                dispatch(setAlert(res.msg,"success"))
               
               } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg,"danger"))
            }
           setLoader(false)
           handleClose()
        }).catch((err) => {
         console.log(err)
           
        })
}
export const adminMangerRejecteddAction = (data,setLoader,handleClose) => (dispatch, getState) => {

    commonAxios("admin-reject-task", data, dispatch,getState().auth.token)
        .then((res) => {
         
            if (res.status) {
              
                dispatch(setAlert(res.msg,"success"))
               
               } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg,"danger"))
            }
           setLoader(false)
           handleClose()
        }).catch((err) => {
         console.log(err)
           
        })
}