import React, { useState } from 'react'
import { PieChart, Pie, Sector, Cell } from "recharts";
import { ScrollPanel } from 'primereact/scrollpanel';
import { GoDotFill } from "react-icons/go";
import { BsFolder } from "react-icons/bs";
import { FaArrowTrendUp } from "react-icons/fa6";
import { PiBatteryChargingLight, PiListChecks, PiUsersThree } from "react-icons/pi";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import TitleBar from "../../Common/TitleBar";
import DashboardHeader from "../../Common/DashboardHeader";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import SidePanelAdmin from '../../Common/SidePanelAdmin';

const AdminDashboard = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // piechart..........

    const data = [
        { name: "Group A", value: 300 },
        { name: "Group B", value: 100 },
        { name: "Group c", value: 500 }
    ];
    const COLORS = ["#BAEDBD", "#95A4FC", "#1C1C1C"];
    // BarChart.......
    const data1 = [
        {
            name: 'Jan',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Feb',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Mar',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Apr',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'May',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Jun',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Jul',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Aug',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Sep',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Oct',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Nov',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Dec',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <>

            <TitleBar title="Dashboard" />
            <div className="dashboard creator_dashboard  container-fluid overflow-hidden">
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                        <SidePanelAdmin />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 p-0">
                        <div>
                            <div className="row">
                                <div className="col-12 ps-md-0 pe-md-3 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader />

                                        <ScrollPanel className="custombar2 content-area pe-2">
                                            <div className="p-4">
                                                <div className="do_task_wrapper ">
                                                    <div className="row justify-content-between">
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Total Tasker</h3>
                                                                    <PiUsersThree className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>29</span>
                                                                    <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task d2">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Total Task Creator</h3>
                                                                    <PiUsersThree className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>15</span>
                                                                    <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Total Tasks</h3>
                                                                    <PiListChecks className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>31</span>
                                                                    <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp className="down_trend" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task d2">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Total Spending</h3>
                                                                    <PiBatteryChargingLight className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>$93.8</span>
                                                                    <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task d2">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Total Payouts</h3>
                                                                    <PiBatteryChargingLight className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>$93.8</span>
                                                                    <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-xl-6 col-12">
                                                        <div className="campaign-stat-left admin_graph_wrap w-100">
                                                            <div className="account-block status">
                                                                <h6>Task Status</h6>
                                                                <div>
                                                                    <div className='row'>

                                                                        <div className="col-xl-6 col-12">
                                                                            <div className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, width: 350, margin: '0 auto' }}>
                                                                                <ResponsiveContainer width="100%" height="100%">
                                                                                    <PieChart>
                                                                                        <Pie
                                                                                            data={data}
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            innerRadius={60}
                                                                                            outerRadius={80}
                                                                                            fill="#8884d8"
                                                                                            paddingAngle={5}
                                                                                            dataKey="value"
                                                                                        >
                                                                                            {data.map((entry, index) => (
                                                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                                            ))}
                                                                                        </Pie>
                                                                                    </PieChart>
                                                                                </ResponsiveContainer>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-xl-6 col-12">
                                                                            <ul className='graph_data'>
                                                                                <li>
                                                                                    <p><GoDotFill style={{ color: "#1C1C1C", marginRight: "3px" }} />Competed</p>
                                                                                    <span>67.6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />In Progress</p>
                                                                                    <span>26.4%</span>
                                                                                </li>
                                                                                <li>
                                                                                    <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} />Admin Approval Pending</p>
                                                                                    <span>6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} />Task Creator Approval Pending</p>
                                                                                    <span>6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} />Rejected</p>
                                                                                    <span>6%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-12">
                                                        <div className="campaign-stat-right admin_graph_wrap w-100 ps-0 pe-0">
                                                            <div className="account-block status">
                                                                <h6>Average Task Rating</h6>
                                                                <div>

                                                                    <div className="row">
                                                                        <div className="col-xl-12 col-12">
                                                                            <div className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 377, maxWidth: 500, margin: '0 auto' }}>
                                                                                <ResponsiveContainer width="100%" height="100%">
                                                                                    <PieChart>
                                                                                        <Pie
                                                                                            data={data}
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            startAngle={180}
                                                                                            endAngle={0}
                                                                                            innerRadius={60}
                                                                                            outerRadius={80}
                                                                                            fill="#8884d8"
                                                                                            paddingAngle={5}
                                                                                            dataKey="value"
                                                                                        >
                                                                                            {data.map((entry, index) => (
                                                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                                            ))}
                                                                                        </Pie>
                                                                                    </PieChart>
                                                                                </ResponsiveContainer>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-xl-12 col-12">
                                                                            <div className="admin_graph_status">
                                                                                <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />Negative</p>
                                                                                <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />Neutral</p>
                                                                                <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />Positive</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="account-block mt-4">
                                                    <h6>Tasks Overview</h6>


                                                    <div className="task_overview">
                                                        <ResponsiveContainer width="100%" height={300}>
                                                            <BarChart
                                                                data={data1}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                                barSize={20}
                                                            >
                                                                <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <Bar dataKey="pv" fill="#A8C5DA" background={{ fill: "#eee" }} />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>

                                        </ScrollPanel>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AdminDashboard
