import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import { ScrollPanel } from "primereact/scrollpanel";

import MySpending from './MySpending';
import InvoiceHistory from './InvoiceHistory';

function SpendingTaskCreator() {

 const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const [isActive2, setIsActive2] = useState(false);
  const toggleNotification = () => {
    setIsActive2(!isActive2);
  };

   return (
      <>
         <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
            <SidePanelTaskCreator />

            <div className="dashboard-in">
               <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} />
               <ScrollPanel className="custombar2 content-area">
                  <div className="content-area-in tab-style">
                     <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <div className="tab-bar">
                           <Nav variant="pillsb">
                              <Nav.Item><Nav.Link eventKey="tab-1">My Spending</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-2">Invoice History</Nav.Link></Nav.Item>
                           </Nav>
                        </div>
                        <Tab.Content>
                           <Tab.Pane eventKey="tab-1">
                              <MySpending />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-2">
                              <InvoiceHistory />
                           </Tab.Pane>
                        </Tab.Content>
                     </Tab.Container>
                  </div>
               </ScrollPanel>
               <DashboardFooter />
            </div>
         </div>

      </>
   )
}
export default SpendingTaskCreator;