import React, { useState } from 'react';
import Navbar from '../Common/Navbar'
import varifImg from '../../images/social icon/EnvelopeSimple.svg'
import lessThen from '../../images/icon/lessthen.svg'
import { IoMdClose } from "react-icons/io";
import OTPInput, { ResendOTP } from "otp-input-react";
import { FaSpider, FaSpinner } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { otpMobileVerfication, otpVerfication, ReSendOtpAction } from '../../Redux/Actions/AuthActions';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../Redux/Actions/AlertActions';
import { FaMobileAlt } from "react-icons/fa";
import VerifcationSuccess from './VerificationSuccess';
import TitleBar from '../Common/TitleBar';


const MobileVerification = () => {
    // console.log("location",location)
    const [OTP, setOTP] = useState("");
    const navigation = useNavigate()
    const [loader, setLoader] = useState(false)
    const location = useLocation()
    const dispatch = useDispatch()
    const [isDisabled, setIsDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleClose=()=>setShowModal(false)
    const handleverify = () => {

        setLoader(true)
        if (OTP.length < 4) {

            setLoader(false)
            dispatch(setAlert("Please enter OTP", "danger"))
        }
        else {
            let Data = {
                "mobile": location?.state?.mobile,
                "otp": OTP,
                "session_id": location?.state?.Id
            }
            
            dispatch(otpMobileVerfication(Data, setLoader, navigation,setShowModal))
        }

    }

    const handleResendOtp = () => {
        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false);
        }, 20000)

        let fromData = {
            type: "mobile",
            "session_id": location?.state?.Id
        }
        dispatch(ReSendOtpAction(fromData))

    }
    return (
        <>
          <TitleBar title="Verification" />
           <Navbar />
            <section className="verification_wrapper login_wrapper">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-12">
                         
                            <div className="sign_from_main">
                                <div className="col-12">
                                    <div className="sign_form verifi_from align-items-center">
                                        <div className="top_wrapper">
                                            {/* <div className="back_wrap">
                                                <img src={lessThen} alt="" />
                                            </div> */}

                                            {/* <div className="close_wrap">
                                                <IoMdClose />
                                            </div> */}
                                        </div>
                                        <div className="verification-content mt-5">
                                            <div className="verif_icon">
                                                {/* <img src={varifImg} alt="" /> */}
                                                <span className='d-flex align-items-center justify-content-center' style={{ backgroundColor: "#fbe4e4", borderRadius: "50px", width: "60px", height: "60px", padding: "10px" }}>
                                                    <FaMobileAlt style={{ fontSize: "40px" }} />
                                                </span>

                                            </div>
                                            <div className="verif_para">
                                                <h3>
                                                    Check your Mobile
                                                </h3>
                                                <p>
                                                    A verification code has been sent to your Mobile Number. Please enter the code received in your Mobile Number

                                                </p>
                                                <h4>{location?.state?.mobile}</h4>
                                            </div>
                                        </div>
                                        <div className="verification_code">
                                            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                                            {/* <div className="verifi_number me-2">
                                                <span>5</span>
                                            </div>
                                            <div className="verifi_number me-2">

                                            </div>
                                            <div className="verifi_number me-2 ">

                                            </div>
                                            <div className="verifi_number ">

                                            </div> */}
                                        </div>
                                        <div className="resend_code">
                                            {/* <span>Resend (60s)</span> */}
                                            {/* <ResendOTP onResendClick={handleResendOtp} /> */}
                                            <button type="button" className=" btn " onClick={handleResendOtp} disabled={isDisabled}>Resend OTP </button>


                                        </div>
                                        <div className="common_btn mb-4" onClick={handleverify}>
                                            <button type="button" className=" btn ">Verify {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <VerifcationSuccess show={showModal} close={handleClose} type="Mobile Number"/>
            </section>

        </>
    )
}

export default MobileVerification