import React, { useState, useEffect } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import { IoMdClose } from "react-icons/io";
import { FetchTaskTaskerData } from '../../Redux/Actions/TaskTaskerActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function NotificationBar() {
    const Taskdata = useSelector(state => state.TaskData.data)
    const [data, setData] = useState('')
const navigation=useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(FetchTaskTaskerData());
    }, []);


    useEffect(() => {
        if (Taskdata) {
            setData(Taskdata)

        }


    }, [Taskdata])


   

    return (
        <>
            <div className="notification-bar">

                {/* <div className="notification-close"><IoMdClose /></div> */}

                <div className="notification-card">
                    <div className="notification-title first">
                        <h6>Recommended Tasks</h6>
                    </div>
                    <ScrollPanel className="notification-list notification-scroll">
                        <ul>
                            {data?.recommended_task?.length > 0 ? data?.recommended_task.map((curElem, i) => {

                                return (
                                    <li key={i}  onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })}>
                                        <div className="notification-img"><img src={curElem?.profile ? curElem?.profile : "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg"} alt="" /></div>
                                        <div className="notification-txt">
                                            <h6>{curElem?.title}</h6>
                                            <p>{curElem?.created_at}</p>
                                        </div>
                                    </li>
                                )

                            }) : <div className='d-flex justify-content-center align-items-center'>No tasks found</div>}

                        </ul>
                    </ScrollPanel>
                </div>

                <div className="notification-card">
                    <div className="notification-title">
                        <h6>Bookmarked Tasks</h6>
                    </div>
                    <ScrollPanel className="notification-list notification-scroll">
                        <ul>
                            {data?.saved_task?.length > 0 ? data?.saved_task.map((curElem, i) => {

                                return (
                                    <li key={i}  onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })}>
                                        <div className="notification-img"><img src={curElem?.profile ? curElem?.profile : "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg"} alt="" /></div>
                                        <div className="notification-txt">
                                            <h6>{curElem?.title}</h6>
                                            <p>{curElem?.created_at}</p>
                                        </div>
                                    </li>
                                )

                            }) : <div className='d-flex justify-content-center align-items-center'>No tasks found</div>}

                        </ul>
                    </ScrollPanel>
                </div>

                <div className="notification-card">
                    <div className="notification-title">
                        <h6>In Progress Tasks</h6>
                    </div>
                    <ScrollPanel className="notification-list notification-scroll">
                        <ul>
                            {data?.ongoing_task?.length > 0 ? data?.ongoing_task.map((curElem, i) => {

                                return (
                                    <li key={i}  onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })}>
                                        <div className="notification-img"><img src={curElem?.profile ? curElem?.profile : "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg"} alt="" /></div>
                                        <div className="notification-txt">
                                            <h6>{curElem?.title}</h6>
                                            <p>{curElem?.created_at}</p>
                                        </div>
                                    </li>
                                )

                            }) : <div className='d-flex justify-content-center align-items-center'>No tasks found</div>}

                        </ul>
                    </ScrollPanel>
                </div>

            </div>
        </>
    )
}

export default NotificationBar;