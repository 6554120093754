import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { FaQuestionCircle, FaSpinner } from "react-icons/fa";
import { RxCross1 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { adminApprovedAction } from '../../../Redux/Actions/AdminPayOutAction';
import { adminMangerApprovedAction } from '../../../Redux/Actions/AdminManagerNavAction';

const AdminAPproveModal = (props) => {
    const[loader,setLoader]=useState(false)
    const dispatch=useDispatch()
   
    const handleApproved=()=>{
        setLoader(true)
        if(props.producatId){
          
            if(props.type==="allTasks"){
                let data={
                    slug:props.producatId
                }
                    dispatch(adminMangerApprovedAction(data,setLoader,props.handleClose))
            }else{
                let data={
                    id:props.producatId
                }
            
            dispatch(adminApprovedAction(data,setLoader,props.handleClose))
            }
        }
    }
    return (
        <>
            <Modal className="theme-modal admin-approve" show={props.show} onHide={props.handleClose} backdrop="static" centered>

                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center modal-btn flex-column gap-2 justify-content-between'>
                        <span style={{right:"20px"}} className='ms-auto' onClick={props.handleClose}><RxCross1 /></span>
                        <FaQuestionCircle style={{ fontSize: "70px", marginBottom:"15px" }} />
                        <h5> Are You Sure</h5>
                        <p style={{fontSize:"14px"}}>Are Your Sure Want to Approve this task</p>
                        <div className="verify_btn mt-2 d-flex gap-3">
                            <button className="theme-btn cancel-btn" type="button" style={{ padding: "8px 24px" }} onClick={props.handleClose}>Cancel </button>
                            <button className="theme-btn approve-btn" type="button" style={{ padding: "8px 24px" }} onClick={handleApproved}>Approve{loader?<FaSpinner className='spin ms-1' style={{color:"white"}}/>:""} </button></div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default AdminAPproveModal;
