import React from 'react'
import NavCreatorTask from './NavCreatorTask'
import DashboardHeader from "../../Common/DashboardHeader";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';

const CreatorTask = () => {
    return (
        <>
            <div className="dashboard creator_task_container container-fluid overflow-hidden">
                <div className="row">

                    <div className="col-lg-2 col-md-3 col-sm-4 col-12 pe-0">
                        <SidePanelTaskCreator />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 pt-0 pb-0 ps-0">
                        <div className="dashboard-in ">

                            <DashboardHeader />

                            <NavCreatorTask />

                        </div>
                    </div>


                </div>
            </div >
        </>
    )
}

export default CreatorTask
