import React, { useEffect,useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import AdminAllTasks from './AdminAllTasks'
import ApproveTasks from './ApproveTasks'
import AdminTaskSubmissions from './AdminTaskSubmissions'
import DashboardHeader from '../../Common/DashboardHeader'
import SidePanelAdmin from '../../Common/SidePanelAdmin'
import { useScrollTrigger } from '@mui/material'
import { useDispatch } from 'react-redux'
import { FetchAdminManager } from '../../../Redux/Actions/AdminManagerNavAction'
import TitleBar from '../../Common/TitleBar'
const NavTaskManager = () => {
    const dispatch=useDispatch()
    const[loader,setLoader]=useState({
        fetch:true
    })
    const[managerData,setManagerData]=useState([])
    const fetchdata=()=>{
            dispatch(FetchAdminManager(loader,setLoader,setManagerData))
    }
    useEffect(()=>{
        fetchdata()
    },[])
   
    return (
        <>
          <TitleBar title="Tasks" />
            <div className="dashboard creator_new_task container-fluid overflow-hidden">
                <div className="row">

                    <div className="col-lg-2 col-md-3 col-sm-4 col-12 pe-0">
                        <SidePanelAdmin />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 pt-0 pb-0 ps-0">
                        <div className="dashboard-in  ">

                            <DashboardHeader />
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" className="p-3">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">All Tasks</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Approve Tasks</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Approve Submissions</Nav.Link>
                                            </Nav.Item>
                                        </Nav>

                                        <div className="setting-right-side-wrap">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <AdminAllTasks data={managerData.tasklist} loader={loader}/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <ApproveTasks data={managerData.approvedtask} loader={loader}/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <AdminTaskSubmissions data={managerData.tasksubmission} loader={loader}/>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>

                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div >

        </>
    )
}

export default NavTaskManager
