import React, { useState,useEffect } from "react";
import { FaSpider, FaSpinner } from "react-icons/fa";

function TaskPreferenceTabs() {
  const[loader,setLoader]=useState(false)
  const tableData = [
    {
      "id": 1,
      "heading": "Transcription Tasks",
      "description": "Converting audio or video recordings into written text",
    },
    {
      "id": 2,
      "heading": "Data Entry Tasks",
      "description": "Tasks involving inputting, updating, and managing data in various formats",
    },
    {
      "id": 3,
      "heading": "Survey and Market Research Tasks",
      "description": "Completing surveys, polls, and participating in market research studies",
    },
    {
      "id": 4,
      "heading": "Survey and Market Research Tasks",
      "description": "Completing surveys, polls, and participating in market research studies",
    },

    {
      "id": 5,
      "heading": "Survey and Market Research Tasks",
      "description": "Completing surveys, polls, and participating in market research studies",
    },
    {
      "id": 6,
      "heading": "Survey and Market Research Tasks",
      "description": "Completing surveys, polls, and participating in market research studies",
    },
    
  ];
const idSlected=[1,2]
  const [selectedTasks, setSelectedTasks] = useState([]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target
// console.log(value,checked)
    let arr = [...selectedTasks]
    if (checked) {
      arr.push(value)
    } else {
      const indexId = selectedTasks.indexOf(value)
      if (indexId !== -1) {
        arr.splice(indexId, 1)
      }
    }
    setSelectedTasks(arr)
    console.log(arr)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Selected task IDs:", selectedTasks);
  };


  useEffect(() => {
    if (idSlected.length > 0) {
      setSelectedTasks(idSlected)
    }
  }, [])
// console.log("selectedTasks",selectedTasks)
  return (
    <>
      <div className="tabBtn">
        <button className="theme-btn">Add Task Category</button>
      </div>

      <div className="account-block">
        <form onSubmit={handleSubmit}>
          <h6>Task Categories</h6>
          <div className="row task-reference-list">
            {tableData.length > 0 ? tableData.map((curElem) => {
              return (
                <div className="col-sm-6" key={curElem.id}>
                  <div className="task-reference-block">
                    <span className="custom-check">
                      <input
                        type="checkbox"
                        id={curElem.id}
                        value={curElem.id}
                        checked={selectedTasks.includes(curElem.id)}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      <span className="checkMark"></span>
                    </span>
                    <div className="task-reference-info">
                      <h6>{curElem.heading}</h6>
                      <p>{curElem.description}</p>
                    </div>
                  </div>
                </div>
              )
            }) : ""}

          </div>

          <div className="btnGroup mt-4">
            <button className="theme-btn light" type="button">Cancel</button>
            <button className="theme-btn" type="submit">Save Changes {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default TaskPreferenceTabs;
