import React, { useState } from "react";

import { GrLocation } from "react-icons/gr";
import { IoCalendarClearOutline, IoSearchOutline } from "react-icons/io5";
import { BiDotsHorizontalRounded } from "react-icons/bi";

import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';

import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Dropdown as PrimeDropdown } from 'primereact/dropdown';

function AdminUsersTabs() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'Super Admin', code: 'NY' },
        { name: 'Admin', code: 'RM' },
        { name: 'User', code: 'LDN' }
    ];

    return(
        <>

          <div className="tabBtn">
            <button className="theme-btn" onClick={handleShow}>Add New User</button> 
          </div>

          <div className="table-bar mt-0">
            <div className="table-bar-left">
              <div className="table-bar-icon">
                <div className="table-nav">
                  <div className="profile-menu">
                      <Dropdown>
                          <Dropdown.Toggle variant="successN" className="drop-btn">
                            <img src={require("../../../images/icon/plus.svg").default} alt="" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <li className="drop-list">
                                <span className="custom-check">
                                  <input type="checkbox" name="" />
                                  <span className="checkMark"></span>
                                </span>
                                User
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                  <input type="checkbox" name="" />
                                  <span className="checkMark"></span>
                                </span>
                                Email
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                  <input type="checkbox" name="" />
                                  <span className="checkMark"></span>
                                </span>
                                Phone Number
                            </li>
                          </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="profile-menu">
                      <Dropdown>
                          <Dropdown.Toggle variant="successN" className="drop-btn">
                            <img src={require("../../../images/icon/filter.svg").default} alt="" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <li className="drop-list">
                                <span className="custom-check">
                                  <input type="checkbox" />
                                  <span className="checkMark"></span>
                                </span>
                                Inbound
                            </li>
                          </Dropdown.Menu>
                      </Dropdown>
                    </div>
                </div>
                <ul>
                    <li title="Sort by Date">
                      <img src={require("../../../images/icon/sort.svg").default} alt="" />
                    </li>
                </ul>
              </div>
            </div>
            <div className="table-bar-right">
                <div className="header-search">
                  <input type="text" placeholder="Search"/>
                  <span className="left-icon"><IoSearchOutline /></span>
                </div>
            </div>
          </div>

          <div className="table-responsive mt-3">
             <table className="table theme-table">
              <tr>
                <th>
                  <span className="custom-check">
                    <input type="checkbox" />
                    <span className="checkMark"></span>
                  </span>
                </th>
                <th>User</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>User Role</th>
                <th>Date Joined</th>
                <th>Status</th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <span className="custom-check">
                    <input type="checkbox" />
                    <span className="checkMark"></span>
                  </span>
                </td>
                <td><span className="userImg"><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg" alt="" /></span> Natali Craig</td>
                <td>mehedi@vega6.com</td>
                <td>+8801756046137</td>
                <td>Super Admin</td>
                <td><IoCalendarClearOutline /> Just now</td>
                <td><span className="table-stat active">Active</span></td>
                <td>
                <span className="profile-menu">
                  <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        <BiDotsHorizontalRounded />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Link>Action</Link>
                        <Link>Another action</Link>
                        <Link>Something else</Link>
                      </Dropdown.Menu>
                  </Dropdown>
                </span>
                </td>
              </tr>

              <tr>
                <td>
                  <span className="custom-check">
                    <input type="checkbox" />
                    <span className="checkMark"></span>
                  </span>
                </td>
                <td><span className="userImg"><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg" alt="" /></span> Natali Craig</td>
                <td>mehedi@vega6.com</td>
                <td>+8801756046137</td>
                <td>Super Admin</td>
                <td><IoCalendarClearOutline /> A minute ago</td>
                <td><span className="table-stat inactive">Inactive</span></td>
                <td>
                <span className="profile-menu">
                  <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        <BiDotsHorizontalRounded />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Link>Action</Link>
                        <Link>Another action</Link>
                        <Link>Something else</Link>
                      </Dropdown.Menu>
                  </Dropdown>
                </span>
                </td>
              </tr>

              <tr>
                <td>
                  <span className="custom-check">
                    <input type="checkbox" />
                    <span className="checkMark"></span>
                  </span>
                </td>
                <td><span className="userImg"><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg" alt="" /></span> Natali Craig</td>
                <td>mehedi@vega6.com</td>
                <td>+8801756046137</td>
                <td>Super Admin</td>
                <td><IoCalendarClearOutline /> Feb 2, 2023</td>
                <td><span className="table-stat banned">Banned</span></td>
                <td>
                <span className="profile-menu">
                  <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        <BiDotsHorizontalRounded />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Link>Action</Link>
                        <Link>Another action</Link>
                        <Link>Something else</Link>
                      </Dropdown.Menu>
                  </Dropdown>
                </span>
                </td>
              </tr>
             </table>
          </div>

        <Modal className="modalRight" show={show} onHide={handleClose}>
          <Modal.Body>

          <div className='modalTitle'>
                <h6>Create New User</h6>
           </div>

          <ScrollPanel className="modalScroll">
               
              <h6 className='pt-4'>Mehedi Hasan</h6>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Name</label>
                <input className="input" type="text" name="" id="" value="Mehedi Hasan" />
              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Email</label>
                <input className="input" type="text" name="" id="" value="mehedi@vega6.com" />
              </div>
              <div className="input-wrap alt">
                <label className="label" htmlFor="">Phone Number</label>
                <input className="input" type="text" name="" id="" value="+8801756046137" />
              </div>
              <div className="input-wrap alt mb-4">
                 <label className="label" htmlFor="">Select Role</label>
                 <PrimeDropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" 
                placeholder="Select a City" className="input" />
              </div>

              <div className="modal-button-bottom">
                <button className="theme-btn bdr" onClick={handleClose}>Cancel</button>
                <button className="theme-btn">Save Changes</button>
              </div>

          </ScrollPanel>
             
          </Modal.Body>
        </Modal>

        </>
    )
}

export default AdminUsersTabs;