import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState, useEffect } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { IoLocationOutline } from 'react-icons/io5'
import { PiUserCircle } from 'react-icons/pi'
import frame from '../../../images/icon-2/Frame.svg'
import { BsDot } from "react-icons/bs";
import Small from '../../../images/icon-2/Small.png';
import { IoIosStarOutline } from 'react-icons/io'
import { FaSpinner } from "react-icons/fa";
import Pagination from "../../Common/Pagination";
import { ProgressBar } from 'primereact/progressbar';
import AdminCreatorNav from './AdminTaskDeatils/AdminCreatorNav'

const AdminReviews = (props) => {
    const [reviewData, setReviewData] = useState([])

    useEffect(() => {
        if (props.data) {
            setReviewData(props?.data)
        }
        else {
            setReviewData([])
        }
    }, [props.data])

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = reviewData.slice(indexofFirstTodo, indexofLastTodo);
   
    return (
        <>
            {
                props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
                    <ScrollPanel className="custombar2 content-area pt-2">
                        <div className="main_task_details ps-4 pe-4 pb-4">
                            <div className="do_task_wrapper ">
                            <AdminCreatorNav data={props.profiledata} />
                                <div className="row">
                                    <div className="account-block ex">


                                        <div className="review-list mt-2 mx-2">
                                            <ul>
                                                {
                                                    currentTodo?.length > 0 ? currentTodo?.map((curElem, i) => {
                                                       
                                                        return (
                                                            <div className='border-bottoms' key={i}>
                                                                <li>
                                                                    <div className="review-list-single">
                                                                        <div className="review-list-single-top">
                                                                            <div className="review-list-single-img"><img src={curElem?.profile ? curElem?.profile : "https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg"} alt="" /></div>
                                                                            <div className="review-list-single-txt">
                                                                                <h4>{curElem.name}</h4>

                                                                                <h6>{curElem.created_at}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <p className="pt-3">{curElem.review}</p>
                                                                    </div>

                                                                </li>
                                                                <div className="rev-card mb-3">
                                                                    <div className="row">
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Availability</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">

                                                                                        <ProgressBar value={20 * curElem.instiuctions} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{curElem.instiuctions}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Cooperation</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * curElem.copperation} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{curElem.copperation}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Communication</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * curElem.communication} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{curElem.communication}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Quality of work</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * curElem.clarity} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{curElem.clarity}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Skills</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * curElem.professionlism} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{curElem.professionlism}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Adhere to deadline</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * curElem.timely} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{curElem.timely}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        )
                                                    }) : <div className="d-flex justify-content-center align-items-center">No Review Recived</div>
                                                }


                                            </ul>


                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Pagination
                                listData={reviewData}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                listArr={reviewData}
                                loader={props.loader.fetch}
                            />




                        </div>



                    </ScrollPanel>

            }

        </>
    )
}

export default AdminReviews
