import React, { useState } from 'react'
import handClick from '../../../images/icon/handClick.svg'
import { Modal } from 'react-bootstrap';
import { FaSpider, FaSpinner } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { ApplicationSubmit } from '../../../Redux/Actions/TaskTaskerActions';
import PendingFilesModel from './PendingFilesModel';
import { setAlert } from '../../../Redux/Actions/AlertActions';


const ApplicationSubmitModal = (props) => {
    const dispatch=useDispatch();
    const[textarea,setTextarea]=useState('')
    const [loader, setLoader] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmit=(e)=>{
        e.preventDefault()
        setLoader(true)
        if(textarea===''){
          dispatch(setAlert("Please type the text","danger"))
          setLoader(false)
        }else{
          const FormData = {
            "text": textarea,
            "type": "text",
            "slug":props?.slugId
        };
       
        setTextarea('')
    dispatch(ApplicationSubmit(FormData,setLoader,handleShow, props.handleClose()))
    }
    }
    return (
        <>
            <Modal className="theme-modal application_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="text-center">Submit Task Application </h3>
                    <p className="text-center pt-2">Before applying for this task, Please carefully review the task requirements and ensure you understand what is expected.</p>
                   <form onSubmit={handleSubmit}>
                    <div className="modal-card mt-4 ">
                        <h6>Cover Letter</h6>
                        <div className="application_submit_wrap">
                            <span className="mb-2 d-block">Write few words to impress your client</span>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="10" name="textarea" value={textarea} onChange={(e)=>setTextarea(e.target.value)}></textarea>
                            {/* <p>Based on the job description, I understand that the main objectives of this project are [briefly mention the key goals or tasks outlined in the job post]. I am confident that my skills in [mention relevant skills] will allow me to [mention how you will achieve the objectives or add value to the project].
                                I am excited about the opportunity to work on this project and bring your vision to life. Thank you for considering my application. I am looking forward to the possibility of collaborating with you. Please feel free to contact me if you have any questions or require further information.
                                I understand the importance of meeting deadlines and am committed to delivering high-quality work within the agreed timeframe. My time management skills and proactive approach.
                                Technical Skills: I am proficient in [mention any specific tools, technologies, or methodologies relevant to the job]. My technical expertise allows me to execute tasks efficiently.
                                Thank you
                                Mehedi</p> */}
                        </div>
                    </div>
                    <div className="application_btn text-center mt-4">
                        <button className='theme-btn ' type="submit">Submit Application {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                    </div>
                    </form>
                </Modal.Body>
            </Modal >
            <PendingFilesModel show={show} handleClose={handleClose} slug={props?.slugId}/>
        </>
    )
}

export default ApplicationSubmitModal
