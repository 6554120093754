import React, { useState, useEffect } from 'react'
import { IoCalendarClearOutline } from "react-icons/io5";
import { FiDownload, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';

import event from "../../../images/icon/events.svg"
import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import Pagination from '../../Common/Pagination';
import { FaSpinner } from 'react-icons/fa';
const AllEarnings = (props) => {

    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([])

    const filterData = (data, filter) => {

        const now = new Date();
        let startDate, endDate;

        switch (filter) {

            case 'week':
                startDate = new Date(now);
                startDate.setDate(now.getDate() - 6);
                endDate = new Date(now);
                // startDate = new Date(now.setDate(now.getDate() - now.getDay())); 
                // endDate = new Date(now.setDate(startDate.getDate() + 6)); 
                break;
            case 'month':
                // startDate = new Date(now.getFullYear(), now.getMonth(), 1); 
                // endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); 
                startDate = new Date(now);
                startDate.setMonth(now.getMonth() - 1); // Start date is one month ago from today
                endDate = new Date(now); // End date is today

                break;
            case 'year':
                // startDate = new Date(now);
                // startDate.setFullYear(now.getFullYear() - 1); // Start date is one year ago from today
                // endDate = new Date(now); // End date is today
                startDate = new Date(now.getFullYear(), 0, 1);
                endDate = new Date(now.getFullYear(), 11, 31);
                break;
            default:

                return data;
        }

        return data.filter(item => {

            const itemDate = new Date(item.created_at);

            return itemDate >= startDate && itemDate <= endDate;
        });
    };


    useEffect(() => {

        setFilteredData(filterData(data, filter));
    }, [data, filter]);
    useEffect(() => {
        if (props.data) {
            setData(props.data)
        } else {
            setData([])
        }
    }, [props.data])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);

    return (
        <>
        {
              props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
              <>
               <div className="account-block mt-4">

<div className='d-flex align-items-center justify-content-between'>
    <h6>All Earnings</h6>
    <div className='input-wrap simple'>
        <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
                {filter}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setFilter("week")}>This Week</Dropdown.Item>
                <Dropdown.Item onClick={() => setFilter("month")}>This Month</Dropdown.Item>
                <Dropdown.Item onClick={() => setFilter("year")}>This Year</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
</div>

<div className='table-responsive mt-4'>
    <table className="table theme-table">
        <thead>
            <tr>
                <th>Task ID</th>
                <th>Task Title</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>

                {/* <th>Invoice</th> */}
            </tr>
        </thead>
        <tbody>
            {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                return (<tr key={i}>
                    <td data-label="Task ID">{curElem.id}</td>
                    <td data-label="Task Title">{curElem.title}</td>
                    <td data-label="Date"><img src={event} alt="" />{curElem.created_at}</td>
                    <td data-label="Amount">₹{curElem.amount}</td>
                    <td data-label="Status" className='text-center'>{curElem.type}</td>


                </tr>)
            }) :
                <td className='d-flex justify-content-center align-items-center' colspan={7}>No taskers Data</td>
            }
        </tbody>
    </table>

    <Pagination

        listData={filteredData}
        pagination={pagination}
        setPagination={setPagination}
        currentTodo={currentTodo}
        listArr={data}
        loader={props.loader.fetch}
    />
</div>
</div>
              </>
        }
        </>
       
    )
}

export default AllEarnings