import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { IoSearchOutline } from 'react-icons/io5';
import { ScrollPanel } from 'primereact/scrollpanel';
import { LuDownload } from 'react-icons/lu';
import event from '../../../images/icon/events.svg';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PayoutPDF from './PayoutPDF'; // Import the PDF component
import Pagination from '../../Common/Pagination';
import { FaSpinner } from 'react-icons/fa';

const PayoutHistory = (props) => {

    const [searchInput, setSearchInput] = useState('')
    const [type, setType] = useState(props.type);
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [data, setData] = useState([])







    const handlesort = () => {

        const sortedData = [...filteredData].sort((a, b) => {
            const dateA = new Date(a.withdraw_date);
            const dateB = new Date(b.withdraw_date);

            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {

        if (data) {
            if (searchInput) {

                const newFilteredData = data.filter(val =>
                    String(val.amount || '').includes(searchInput.toLowerCase())||
                    String(val.id || '').includes(searchInput.toLowerCase())
                );

                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data);
            }
        }
    }, [searchInput,data]);

    useEffect(() => {
        if (props.data) {
            setData(props.data)
        } else {
            setData([])
        }
    }, [props.data])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);

    return (
        <>
            <ScrollPanel className="custombar2 earnings_payouts content-area">
                <div className="content-area-in p-0 " style={{ marginTop: "-10px" }}>
                    <div className="table-bar">
                        <div className="table-bar-left">
                            <div className="table-bar-icon">
                                <div className="table-nav">
                                    <div className="profile-menu">
                                    </div>
                                    <div className="profile-menu">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="successN" className="drop-btn">
                                                <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                <li className="drop-list">
                                                    <span className="custom-check">
                                                        <input
                                                            type="checkbox"
                                                            name="agentType"
                                                        />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                    dolor sit amet
                                                </li>
                                                <li className="drop-list">
                                                    <span className="custom-check">
                                                        <input
                                                            type="checkbox"
                                                            name="agentType"
                                                        />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                    dolor sit amet.
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <ul>
                                    <li title="Sort by Date" >
                                        <img src={require("../../../images/icon/sort.svg").default} alt="" onClick={handlesort} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="table-bar-right">
                            <div className="header-search">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    name="searchInput"
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                                <span className="left-icon"><IoSearchOutline /></span>
                            </div>
                        </div>
                    </div>

                    <div className="account-block bg-transparent mt-4">
                        {props.loader.fetch ? <div className='d-flex align-items-center justify-content-center'><FaSpinner className='spin ms-1' style={{fontSize:"40px"}}/></div> :
                            <div className="table-responsive earnings_table mt-1">
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            {/* <th>
                                            <span className="custom-check">
                                                <input
                                                    type="checkbox"
                                                />
                                                <span className="checkMark"></span>
                                            </span>
                                        </th> */}
                                            <th>Tansactoion ID</th>
                                            <th>Description</th>
                                            <th>Date</th>
                                            <th>Payout Amount</th>
                                            <th>Remaining Balance</th>
                                            <th className='text-center'>Status</th>
                                            {/* <th>Invoice</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                                            const bankDetails = JSON.parse(curElem.bank_details)
                                            // console.log("bank",bank)

                                            return (
                                                <tr key={i}>
                                                    {/* <td data-label="">
                                            <span className="custom-check">
                                                <input
                                                    type="checkbox"
                                                />
                                                <span className="checkMark"></span>
                                            </span>
                                        </td> */}

                                                    <td data-label="Transaction ID">{curElem.id}</td>
                                                    <td data-label="Description">Bank Transfer for ₹{curElem.amount} to {bankDetails.account_no}</td>
                                                    <td data-label="Date"><img src={event} alt="" />{curElem.withdraw_date}</td>
                                                    <td data-label="Payout Amount">₹{curElem.amount}</td>
                                                    <td data-label="Remaining Balance">₹{curElem.balance}</td>
                                                    <td data-label="Status">
                                                        {curElem.status === 1 ? <div className='earn_status complete'>Complete</div> : curElem.status === 2 ? <div className='earn_status'>Rejected</div> : <div className='earn_status'>Pending</div>}
                                                    </td>
                                                    {
                                                        curElem.status === 1 ?
                                                            <td data-label="Invoice">
                                                                <PDFDownloadLink
                                                                    document={<PayoutPDF data={curElem} />}
                                                                    // fileName="payout_history.pdf"
                                                                    fileName={`payout_history_${i}.pdf`}
                                                                >
                                                                    {({ loading }) => (loading ? 'Loading document...' : <><LuDownload /> PDF</>)}
                                                                </PDFDownloadLink>
                                                            </td>
                                                            : ""
                                                    }

                                                </tr>
                                            )
                                        }) : <td className='text-center py-3' colSpan={7}>No tasks found</td>}

                                    </tbody>
                                </table>
                                <Pagination

                                    listData={filteredData}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    currentTodo={currentTodo}
                                    listArr={data}
                                    loader={props.loader.fetch}
                                />
                            </div>
                        }
                    </div>
                </div>
            </ScrollPanel >
        </>
    );
}

export default PayoutHistory;
