import React, { useState } from 'react'
import listImg from '../../../images/PPImage.png'
import Submission from '../../../images/icon-2/Submission.svg'
import application from '../../../images/icon-2/application.svg'
import CursorClick from '../../../images/icon-2/CursorClick.svg'
import MoneyWavy from '../../../images/icon-2/MoneyWavy.svg'
import { BsCalendar4Event } from "react-icons/bs";
import { IoIosHeartEmpty, IoIosStarOutline } from 'react-icons/io';
import { TbPointFilled } from "react-icons/tb";
import { useNavigate, useParams } from 'react-router-dom';

const TasksCard = ({ curlElem, setSelectedId, selectedId }) => {
    const navigate = useNavigate();
    const handleCardClick = () => {
        setSelectedId(curlElem.id);
        navigate("/creator/details", { state: { "id": curlElem } });
    };
    return (
        <div onClick={handleCardClick}>
            <div className="task_section mt-3" key={curlElem.id}>
                <div className="d-flex justify-content-between">
                    <div className="task_list_wrap">
                        <div className="task_list_img">
                            <img src={listImg} alt="" />
                        </div>
                        <div className="list_head_wrapper">
                            <div className="list_head">
                                <h3>{curlElem.title}</h3>
                                <span><TbPointFilled style={{ fontSize: '10px' }} /> {curlElem.time}</span>
                                <div className='earn_status'>{+curlElem.status === 0 ? "Pending" : +curlElem.status === 1 ? "Approved" : +curlElem.status === 2 ? "Rejected" : ""}</div>
                            </div>
                            <div className="list_head_bottom">
                                <div className="head_info">
                                    <span>Fixed Price - {curlElem.price}</span>
                                    <BsCalendar4Event />
                                    <span>Due Date: {curlElem.due_date}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="task_like">
                        <IoIosHeartEmpty />
                    </div>
                </div>
                <div className="head_para">
                    <p>{curlElem.description}</p>
                </div>
                <div className="task_tag_wrap">
                    <div className="task_tag" >
                        <span>{curlElem.experience_name}</span>
                    </div>
                    <div className="task_tag" >
                        <span>{curlElem.category_name}</span>
                    </div>
                </div>
                <div className="task_person_info">
                    <div className="task_verification">
                        <img src={application} alt="" />
                    </div>
                    <div className="task_verification">
                        <img src={Submission} alt="" />
                        <span>{curlElem.type === "limited" ? curlElem.max_allowed_submission : "Unlimited"} Submissions</span>
                    </div>
                    <div className="task_verification">
                        <img src={MoneyWavy} alt="" />
                    </div>
                    <div className="task_verification">
                        <img src={CursorClick} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TasksCard
