import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import aboutPro from '../../../../images/aboutpro.png'
import user from '../../../../images/user.png'
import { IoIosStarOutline } from 'react-icons/io';
import { IoLocationOutline, IoPersonCircleOutline } from 'react-icons/io5';
import { BsStarFill, BsStarHalf } from "react-icons/bs";

const AboutClient = (props) => {
    const [otherClient, setOtherClient] = useState([])
    const[client,setClient]=useState({})
   

    useEffect(() => {
        if (props?.aboutClient) {
            const otherTasks = props.aboutClient?.othertask || [];
            const client1=props.aboutClient||{}
            setClient(client1)
            setOtherClient(otherTasks);
        }
    }, [props.aboutClient]);
    // console.log("client", client)
    return (
        <>
            <div className="side_task_wrapper task_details_side_wrapper">
                <ScrollPanel className="custombar2 content-area" style={{ height: "100vh" }}>
                    <div className="account-block top mt-1 bg-transparent text-center ">
                        <h6> About Client</h6>
                    
                    </div>
                    <div className="account-block about_box text-center" style={{ paddingBottom: "50px" }}>

                        <div className="about_img">
                            <img className='img-fluid' src={client?.profile?client?.profile:aboutPro} alt="" />
                        </div>
                        <h6 className='mt-3'> {client?.name}</h6>
                        <div className="about_review">

                            {+client?.rating?.length > 0
                                ? [...Array(Math.floor(+client.rating))].map((star, index) => (
                                    <BsStarFill key={index} color={"gold"} />
                                ))
                                : ""}
                            {+client.rating % 1 !== 0 ? <BsStarHalf color={"gold"} /> : ""}
                        </div>
                        <div className="about_info">
                            <div className="task_verification">
                                <IoPersonCircleOutline />
                                <span>Identity Verified</span>
                            </div>
                            {/* <div className="task_verification">
                                <IoLocationOutline />
                                <span>Dhaka,Bangladesh</span>
                            </div> */}

                        </div>
                        <div className="about_task_info">
                            <div className="task_process  pe-2">
                                <span>Total Spend</span>

                                <div className="progress_wrap" >
                                    <p>{client?.totalspend}</p>
                                </div>

                            </div>
                            <div className="task_process ps-2 pe-2">
                                <span className="text-center w-100">Tasks</span>
                                <div className="task_process_bar text-center">
                                    <p>{client?.totaltask}</p>
                                </div>
                            </div>

                            <div className="task_process ps-2" style={{ border: "0" }}>
                                <span className="text-center w-100">Price</span>
                                <div className="task_process_bar text-center">
                                    <p>₹{client?.price}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="Recommend_tasks">
                        <h6 className='mt-3'>  Other Tasks Of This Client</h6>

                        <div className="Recommend_tasks_wrapper">
                            {otherClient.length > 0 ? otherClient.map((curElem, i) => {
                                return (
                                    <div className="Recommend_tasks_cont">
                                        <div className="recom_img">
                                            <img src={curElem?.profile ? curElem?.profile : user} alt="" />
                                        </div>
                                        <div className="recom_para">
                                            <p>{curElem?.title}  </p>
                                            <span>{curElem?.created_at}</span>
                                        </div>
                                    </div>
                                )
                            }) : ""}


                        </div>
                    </div>

                </ScrollPanel >
            </div>
        </>
    )
}

export default AboutClient
