import React, { useEffect, useState } from 'react';

import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';

import { Link, useLocation } from 'react-router-dom';

import PersonalDetailsTabs from "./PersonalDetailsTabs";
import OngoingTasksTabs from './OngoingTasksTabs';
import CompletedTasksTabs from './CompletedTasksTabs';
import EarningsTabs from './EarningsTabs';
import PaymentMethodsTabs from './PaymentMethodsTabs';
import ReviewsTabs from './ReviewsTabs';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import { useDispatch } from 'react-redux';
import { activeAccountUserDetails, banAccoutUserDetails, FetchAdminTaskerUserDetails } from '../../../Redux/Actions/AdminManagerUserAction';
import ProfileInfo from './ProfileInfo';
import AdminBanModel from '../AdminModal/AdminBanModel';
import AdminActiveModel from '../AdminModal/AdminActiveModel';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { FaSpinner } from 'react-icons/fa';

function ProfileDetailsAdmin() {
const location=useLocation()
const userId=location?.state?.slug
const dispatch=useDispatch()
const[userData,setUserData]=useState({})
const[loader,setLoader]=useState({
   fetch:true,
   active:false,
   banAccount:false
})
const[reason,setReason]=useState('')
const[showModel,setShowModel]=useState(false)
const[showModel1,setShowModel1]=useState(false)
  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const [isActive2, setIsActive2] = useState(false);
  const toggleNotification = () => {
    setIsActive2(!isActive2);
  };

  const fetchDataTasker=()=>{
   let formData={
      "id":userId
   }
   dispatch(FetchAdminTaskerUserDetails(formData,loader,setLoader,setUserData))

  }
  useEffect(()=>{
   if(userId){
      fetchDataTasker()
   }

  },[userId])
  const handleShow=()=>setShowModel(true)
  const handleClose=()=>setShowModel(false)
  const handleShow1=()=>setShowModel1(true)
  const handleClose1=()=>setShowModel1(false)
 
  
  const handleBanAccount=()=>{
   setLoader({
      ...loader,
      banAccount:true

   })
   let data={
      id:userData?.profiledata?.id
   }
   dispatch(activeAccountUserDetails(data,loader,setLoader,handleClose1))
  }
  const handleActive=()=>{
   setLoader({
      ...loader,
      active:true

   })
   let data={
      id:userData?.profiledata?.id,
      "ban_reason":reason
   }
   if(reason===''){
      setLoader({
         ...loader,
         active: false

     })
         dispatch(setAlert("Reason  is requried","danger"))
   }
   else{
 dispatch(banAccoutUserDetails(data,loader,setLoader,handleClose))
   }
  
  }

   return (
      <>
         <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
         <SidePanelAdmin />
{
loader.fetch?<div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{fontSize:"40px",height:"100vh"}}/></div>

           : <div className="dashboard-in">
            <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} />
               <ScrollPanel className="custombar2 content-area">
                  <div className="content-area-in tab-style">
                     <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <div className="tab-bar">
                           <Nav variant="pillsb">
                              <Nav.Item><Nav.Link eventKey="tab-1">Profile Details</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-2">Ongoing Tasks</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-3">Completed Tasks</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-4">Earnings</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-5">Payment methods</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-6">Reviews</Nav.Link></Nav.Item>
                           </Nav>

                           <div className="tab-bar-right">
                              <ul>
                               
                                 {
                                       userData?.profiledata?.isSuspended==="0"?<li><Link className='red' onClick={handleShow}>Ban Account</Link></li>:<li><Link className='red' onClick={handleShow1}>Active</Link></li>
                                 } 
                               
                              </ul>
                           </div>

                        </div>
                        <div className='mt-3'>
                        <ProfileInfo data={userData.profiledata}/>
                        </div>
                       
                        <Tab.Content>
                           <Tab.Pane eventKey="tab-1">
                              <PersonalDetailsTabs data={userData.profiledata} loader={loader}/>
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-2">
                              <OngoingTasksTabs data={userData.ongoing_task} loader={loader}/>
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-3">
                              <CompletedTasksTabs data={userData.completedtask} loader={loader}/>
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-4">
                              <EarningsTabs data={userData.payout} loader={loader}/>
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-5">
                              <PaymentMethodsTabs data={userData.payment_method} loader={loader}/>
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-6">
                              <ReviewsTabs data={userData.review} loader={loader}/>
                           </Tab.Pane>
                        </Tab.Content>
                     </Tab.Container>
                  </div>
                  <AdminBanModel show={showModel} handleClose={handleClose} setReason={setReason} reason={reason} loader={loader} handleBanAccount={handleActive} />
                  <AdminActiveModel show={showModel1} handleClose={handleClose1}  loader={loader} handleBanAccount={handleBanAccount}/>
               </ScrollPanel>
               <DashboardFooter />
            </div>
}  
         </div>

      </>
   )
}
export default ProfileDetailsAdmin;