import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaSpinner } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { LinkSubmissionTasker } from '../../../Redux/Actions/TaskTaskerActions';
import PendingFilesModel from './PendingFilesModel';
import { ImCross } from "react-icons/im";
import { setAlert } from '../../../Redux/Actions/AlertActions';

const LinkSubmissionModel = (props) => {
    const [loader, setLoader] = useState(false);
    const [linkInput, setLinkInput] = useState(['']);
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (index, event) => {
        const newFields = linkInput.slice();
        newFields[index] = event.target.value;
        setLinkInput(newFields);
    };
    const addField = () => {
        setLinkInput([...linkInput, '']);
    };

    const handleRemoveField = (index) => {
        setLinkInput(linkInput.filter((_, i) => i !== index));
    };
    const urlRegex = /^(https?:\/\/|ftp:\/\/|www\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/;

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleSubmitLink = (e) => {
        e.preventDefault();
        setLoader(true);
       
        const allLinksFilled = linkInput.every(link => link.trim() !== '');
        if (!allLinksFilled) {
            dispatch(setAlert("Please fill in all link fields", "danger"));
            setLoader(false);
            return;
        }
      
        const allLinksValid = linkInput.every(link => urlRegex.test(link.trim()));
        if (!allLinksValid) {
            dispatch(setAlert("Please provide valid link", "danger"));
            setLoader(false);
            return;
        }
        const FormData = {
            link: linkInput,
            type: "link",
            "check": isChecked,
            "slug": props?.slugId
        };



        dispatch(LinkSubmissionTasker(FormData, setLoader, handleShow, props.handleClose));


        setLinkInput(['']);
        setIsChecked(false);
    
    };

    return (
        <>
            <Modal
                className="theme-modal link_submit_modal"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton style={{ borderBottom: "0" }}></Modal.Header>
                <Modal.Body className='pt-3'>
                    <h3 className="text-center">Please upload your files for final review</h3>
                    <p className="text-center mt-1">Don't worry, you can upload images, videos, audio, or any format</p>
                    <div className="modal-card mt-4">
                        <form onSubmit={handleSubmitLink}>
                            <h6>Submit Link</h6>
                            <p className='mt-0'>Please submit a link related to your task, don’t paste any malicious link.</p>

                            {linkInput.map((link, index) => (
                                <div key={index} className='mb-3 d-flex align-items-center gap-2'>
                                    <input
                                        type="text"
                                        value={link}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    {linkInput.length > 1 && (

                                        <button
                                            type="button"
                                            className="remove-field-btn"
                                            onClick={() => handleRemoveField(index)}
                                            style={{ border: "none", backgroundColor: "", width: "30px", height: "30px", borderRadius: "8px" }}
                                        >
                                            <ImCross style={{ fontSize: "10px", color: "#474848" }} />


                                        </button>

                                    )}
                                </div>
                            ))}

                            <div className='link_addmore mt-4'>
                                <button type="button" onClick={addField}>+ Add more</button>
                            </div>

                            <div className='link_checkbox d-flex align-items-center mt-4'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <span>By checking this box, Yes, I've followed the instructions and completed the task accordingly.</span>
                            </div>

                            <div className="link_btn text-end mt-5">
                                <button className='theme-btn cancelBtn' type="button" onClick={props.handleClose}>Cancel</button>
                                <button className='theme-btn saveBtn' type="submit">
                                    Sumbit Link{loader ? <FaSpinner className="spin ms-1" /> : null}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <PendingFilesModel show={show} handleClose={handleClose} slug={props?.slugId} />
        </>
    );
};

export default LinkSubmissionModel;
