import React, { useState } from 'react'
import google from '../../images/social icon/google.svg'
import fb from '../../images/social icon/Facebook.svg'
import Navbar from "../Common/Navbar";
import { Dropdown } from 'primereact/dropdown';
import { IoEyeOffOutline, IoPersonCircleOutline } from 'react-icons/io5';
import { FaSpider, FaSpinner } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { commonAxios } from '../../Global/CommonAxios';
import { setAlert } from '../../Redux/Actions/AlertActions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { GoBriefcase } from 'react-icons/go';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import TitleBar from '../Common/TitleBar';
const SignUp = () => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [loader, setLoader] = useState(false)
    const location = useLocation();
    const dispatch = useDispatch()
    const navigation = useNavigate();
    const [view, setView] = useState(false)
    const [view1, setView1] = useState(false)
    const [strength, setStrength] = useState(null);


    const regEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const regpass =  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,16}$/;


    function checkPasswordStrength(password) {
        let strength = 0;
        const lengthCriteria = password.length >= 8;
        const numberCriteria = /[0-9]/.test(password);
        const charactercaseCriteria = /[a-z]/.test(password) || /[A-Z]/.test(password);
        const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (lengthCriteria) strength += 1;
        if (numberCriteria) strength += 1;
        if (charactercaseCriteria) strength += 1;
        if (specialCharacterCriteria) strength += 1;
        return strength;
    }


    const evaluatePasswordStrength = (passswordVal) => {
       
        let strength = '';
        let color = '';
        if (passswordVal <= 1) {
            strength = 'Weak';
            color = '#ff7d50';
        } else if (passswordVal <= 2) {
            strength = 'Average';
            color = '#faad4d';
        } else if (passswordVal <= 3) {
            strength = 'Good';
            color = '#00ba8b';
        } else if (passswordVal <= 4) {
            strength = 'Strong';
            color = '#1c7962';
        }

        return { strength, color };
    };

    const [signInfo, setSignInfo] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        userType: "Tasker",
        // code:"+91",
        phone: ""
    })
    const handleType = (type) => {
        setSignInfo({
            ...signInfo,
            userType: type
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSignInfo({
            ...signInfo,
            [name]: value
        });
        if (name === 'password') {
            const strength = checkPasswordStrength(value);
            setStrength(evaluatePasswordStrength(strength));
        }
    };
    const handleSubmit = (e) => {
      
        e.preventDefault()
        if (regpass.test(signInfo.password) === false) {
            dispatch(setAlert("Use 8 or more characters with a mix of letters, numbers & symbols. ", "danger"));

        } else if (regEmail.test(signInfo.email) === false) {
            dispatch(setAlert("Incorrect Email", "danger"))
        }
        else if (signInfo.phone.length < 10) {

            dispatch(setAlert("Please enter a valid 10-digit mobile number", "danger"))
        } else if (signInfo.password !== signInfo.confirmPassword) {
            dispatch(setAlert("password and confirm password is not equal", "danger"))

        } else if (selectedCountry === null) {
            dispatch(setAlert("Please select country", "danger"))
        }
        else {
            const signData = {
                "name": signInfo?.name,
                "email": signInfo?.email,
                "password": signInfo?.password,
                "confirmPassword": signInfo?.confirmPassword,
                "userType": signInfo?.userType,
                "mobileNumber": selectedCountry?.phoneCode + signInfo?.phone
            }
           
            setLoader(true)

            commonAxios("register", signData).then((res) => {

                if (res.status) {
                    console.log("res", res?.data?.session_id)
                    navigation("/verification", { state: { "email": signInfo?.email, "Id": res?.data.session_id } })

                } else {
                    dispatch(setAlert(res.msg, "danger"));
                }
                setLoader(false)
            }).catch((err) => {
                console.log(err, "err")
                setLoader(false)

            })

        }




    }
    const countries = [
        { name: 'Australia', code: 'AU', phoneCode: '+61' },
        { name: 'Brazil', code: 'BR', phoneCode: '+55' },
        { name: 'China', code: 'CN', phoneCode: '+86' },
        { name: 'Egypt', code: 'EG', phoneCode: '+20' },
        { name: 'France', code: 'FR', phoneCode: '+33' },
        { name: 'Germany', code: 'DE', phoneCode: '+49' },
        { name: 'India', code: 'IN', phoneCode: '+91' },
        { name: 'Japan', code: 'JP', phoneCode: '+81' },
        { name: 'Spain', code: 'ES', phoneCode: '+34' },
        { name: 'United States', code: 'US', phoneCode: '+1' }
    ];

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="d-flex align-items-center ">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div className='ms-2'>{option.name} </div>

                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="d-flex align-items-center ">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div className='ms-2'>{option.name}  ({option.phoneCode})</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3">
                {selectedCountry ? (
                    <span>
                        <b>{selectedCountry.name}</b> selected.
                    </span>
                ) : (
                    'No country selected.'
                )}
            </div>
        );
    };


    return (
        <>
            <TitleBar title="Sign Up" />
            <Navbar />
            <section className="login_wrapper">
                <div className="container-fluid  h-100">
                    <div className="col-12">
                        <div className="row h-100">

                            <div className="sign_from_main">
                                <div className="col-12">
                                    <div className="sign_form">
                                        <form className="sign_form_top" onSubmit={handleSubmit}>

                                            <div className="sign_form_content">

                                                <span>Sign Up</span>
                                                <h3 className="mt-2">Create Your {signInfo.userType} Account Quickly</h3>


                                                <div className="sign_form_content">

                                                    {/* <div className="sign-input-forget mt-1 text-center">
    <p> <span>If you need more info, please check out </span> Help Page</p>
</div> */}



                                                    <div className="sign_Account_wrap">

                                                        <div className="sign_Account" onClick={() => handleType("Tasker")} style={{ border: signInfo.userType === "Tasker" ? "1px solid #000" : "" }}>

                                                            <div className="sign_acc_icon">
                                                                <IoPersonCircleOutline style={{ fontSize: "30px" }} />
                                                            </div>

                                                            <div className="sign_acc_name">
                                                                <span>Tasker Account</span>
                                                                <p>Sign up as a Tasker</p>
                                                            </div>
                                                        </div>

                                                        <div className="sign_Account" onClick={() => handleType("Creator")} style={{ border: signInfo.userType === "Creator" ? "1px solid #000" : "" }}>
                                                            <div className="sign_acc_icon">
                                                                <GoBriefcase />
                                                            </div>

                                                            <div className="sign_acc_name">
                                                                <span>Creator Account</span>
                                                                <p>Sign up as a Creator</p>
                                                            </div>

                                                        </div>

                                                    </div>




                                                </div>
                                                <div className="login-input-wrap">
                                                    <input
                                                        className="login-input"
                                                        type="name"
                                                        placeholder="Full Name"
                                                        name="name"
                                                        value={signInfo.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="login-input-wrap">
                                                    <input
                                                        className="login-input"
                                                        type="email"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={signInfo.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>

                                                <div className="login-input-wrap">
                                                    <div class="input-group phone_code_wrap ">
                                                        <div className="card d-flex justify-content-center phone_code">
                                                            <Dropdown
                                                                value={selectedCountry}
                                                                onChange={(e) => setSelectedCountry(e.value)}
                                                                options={countries}
                                                                optionLabel="name"
                                                                placeholder="Country"
                                                                valueTemplate={selectedCountryTemplate}
                                                                itemTemplate={countryOptionTemplate}
                                                                className="w-full md:w-14rem"
                                                                panelFooterTemplate={panelFooterTemplate}
                                                                dropdownIcon={(opts) => {
                                                                    return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                                                                }} />



                                                        </div>
                                                        <div className="input-logo">
                                                            <input
                                                                type="tel"
                                                                className="login-input"
                                                                placeholder="Phone number"
                                                                name="phone"
                                                                value={signInfo.phone}
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value.replace(/[^0-9]/g, '');
                                                                    setSignInfo({ ...signInfo, phone: newValue });
                                                                }}
                                                                maxLength={10}
                                                                pattern="[0-9]*"
                                                                inputMode="numeric"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="login-input-wrap">
                                                    <div className="input-logo">
                                                        <input
                                                            className="login-input"

                                                            type={view ? "text" : "password"}
                                                            placeholder="Password"
                                                            required
                                                            name="password"
                                                            value={signInfo.password}
                                                            onChange={handleChange}

                                                        />
                                                        <span
                                                            onMouseUp={() => setView(false)}
                                                            onMouseLeave={() => setView(false)}
                                                            onMouseDown={() => setView(true)}
                                                            className="inp-icon"
                                                        >
                                                            <IoEyeOffOutline />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="sign_up_dashed">
                                                    {[

                                                        '#ff7d50', '#faad4d', '#00ba8b', '#1c7962'].map((color, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    backgroundColor: strength && strength.color === color ? color : 'lightgray'
                                                                }}
                                                            />
                                                        ))}
                                                </div>
                                                {strength && (
                                                    <div className="password-strength">{strength.strength}</div>
                                                )}
                                                <div className="valid_text">
                                                    <p>Use 8 or more characters with a mix of letters, numbers & symbols.</p>
                                                </div>
                                                <div className="login-input-wrap">
                                                    <div className="input-logo">
                                                        <input
                                                            className="login-input"
                                                            type={view1 ? "text" : "password"}
                                                            placeholder="Confirm Password"
                                                            required
                                                            name="confirmPassword"
                                                            onChange={handleChange}
                                                            value={signInfo.confirmPassword}
                                                        />
                                                        <span
                                                            onMouseUp={() => setView1(false)}
                                                            onMouseLeave={() => setView1(false)}
                                                            onMouseDown={() => setView1(true)}
                                                            className="inp-icon"
                                                        >
                                                            <IoEyeOffOutline />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="sign-input-forget text-start">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            I Accept the <a href=""> Terms and Conditions</a>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="sign-input-wrap">
                                                    <button type="submit" className="login-btn btn ">Sign Up {loader ? <FaSpinner className="spin ms-1" /> : null} </button>
                                                </div>

                                                <div className="sign-input-forget mt-4 text-center">
                                                    <p > <span>Already have an Account? </span><span onClick={() => navigation("/")} style={{ color: "#1769FF" }}> Sign in</span>  </p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignUp
