import React, { useState,useEffect } from "react";


import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineExclamationCircle, AiOutlineDollar } from "react-icons/ai";
import { IoCalendarClearOutline, IoSearchOutline } from "react-icons/io5";
import { LuDot } from "react-icons/lu";
import { FaRegHeart, FaSpinner } from "react-icons/fa";
import { FiDownload, FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { Dropdown } from 'react-bootstrap';
import ProfileInfo from "./ProfileInfo";
import Pagination from "../../Common/Pagination";
function CompletedTasksTabs(props) {
    const [filteredData, setFilteredData] = useState([])
    const [data, setData] = useState([])
  
    const [searchInput, setSearchInput] = useState('')
    const [sortOrder, setSortOrder] = useState('asc');
    const handleSort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }
    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
        else {
            setData([])
        }
    }, [props.data])
    useEffect(() => {

        if (!searchInput) {

            setFilteredData(data)

        } else {
            const filterSerach = data.filter(item =>
              
                item.title.toLowerCase().includes(searchInput.toLowerCase())

            );
            setFilteredData(filterSerach)
        }

    }, [data, searchInput])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);

  return (
    <>
    {
        props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
            <>
                <div className="table-bar mt-4">
                    <div className="table-bar-left">
                        <div className="table-bar-icon">
                            <div className="table-nav">
                                <div className="profile-menu">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="successN" className="drop-btn">
                                            <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <li className="drop-list">
                                                <span className="custom-check">
                                                    <input type="checkbox" />
                                                    <span className="checkMark"></span>
                                                </span>
                                                Inbound
                                            </li>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <ul>
                                <li title="Sort by Date" onClick={handleSort}>
                                    <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="table-bar-right">
                        <div className="header-search">
                            <input type="text" placeholder="Search" name="searchInput" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                            <span className="left-icon"><IoSearchOutline /></span>
                        </div>
                    </div>
                </div>


                <div className="task-group">
                    {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                        return (
                            <div className="task-block" key={i}>
                                <div className="markIcon"><FaRegHeart /></div>
                                <div className="task-profile">
                                    <div className="task-profile-img"><img src={curElem.profile} alt="" /></div>
                                    <div className="taskInfo">
                                        <div className="taskInfo-top">
                                            <h6>{curElem.title}</h6>
                                            {/* <LuDot style={{ fontSize: 20 }} /> */}
                                            {/* <p>2 Hours ago</p> */}
                                        </div>
                                        <div className="contInfo">
                                            <ul className="pt-2">
                                                <li>
                                                    <AiOutlineDollar />
                                                    <span>Fixed Price -{curElem.price}</span>
                                                </li>
                                                <li>
                                                    <IoCalendarClearOutline />
                                                    <span>Due Date: {curElem.due_date}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <p className="pt-3">{curElem.description}</p>
                                <div className="profile-tag mt-4">
                                    <ul>
                                        <li>{curElem.levelname}</li>
                                        <li>{curElem.category}</li>

                                    </ul>
                                </div>
                                <div className="contInfo">
                                    <ul className="pt-3">
                                        <li>
                                            <PiUserCircleLight />
                                            <span>Identity Verified</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        )
                    }) : <div className="d-flex align-items-center justify-content-center"> No Data Found</div>}


                </div>


                <Pagination
                    listData={filteredData}
                    pagination={pagination}
                    setPagination={setPagination}
                    currentTodo={currentTodo}
                    listArr={data}
                    loader={props.loader.fetch}
                />



            </>

    }
</>

  )
}

export default CompletedTasksTabs;