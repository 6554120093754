import React from 'react'
// import frame from '../../../images/icon-2/Frame.svg'
import frame from "../../../../images/icon-2/Frame.svg"
import { PiUserCircle } from "react-icons/pi";
import { IoInformationCircleOutline, IoLocationOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { Form } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
const AdminCreatorNav = (props) => {

  return (
    <div className="main_task_details ">
    <div className="do_task_wrapper ">
      
                        <div className="row">

                            <div className="col-12 ">
                                <div className="account-block admin_process_wrap  mt-2">
                                    <div className='admin_image_wrap'>
                                        <h6>{props.data?.name}</h6>
                                        <img src={props.data?.profile} alt="" />
                                    </div>
                                    <div className='admin_image_profile'>
                                       
                                        <div>
                                            <AiOutlineMail />
                                            <span>{props.data?.email}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex task_main_wrap">
                                        <div className="task_process">
                                            <span>Profile Compleation</span>

                                            <div className="progress_wrap">
                                                <div className="task_process_bar">
                                                    <div class="progress " style={{ position: "relative" }}>
                                                        <div class="progress-bar" role="progressbar" style={{ width: `${props.data?.profile_compleation}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                        <p style={{ color: "#000", fontWeight: "700" }}> {props.data?.profile_compleation}%</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="task_process ps-3 pe-3">
                                            <span className="text-center w-100">Spending</span>
                                            <div className="task_process_bar text-center">
                                                <p>{props.data?.earning}₹</p>
                                            </div>
                                        </div>
                                        <div className="task_process ps-3 pe-3">
                                            <span className="text-center w-100">Tasks</span>
                                            <div className="task_process_bar text-center">
                                                <p>{props.data?.task}</p>
                                            </div>
                                        </div>
                                        <div className="task_process ps-3 pe-3 " style={{ border: "0" }}>
                                            <span className="text-center w-100">Approval Rate</span>
                                            <div className="task_process_bar text-center">
                                                <p>{props.data?.success_rate}% </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                    </div>
  )
}

export default AdminCreatorNav