import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import edit from '../../../images/icon/PencilLine.svg'
import CurrencyDollar from '../../../images/CurrencyCircleDollar.svg'
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { fetchBankDataAction, payOutRequestAction } from '../../../Redux/Actions/EarningsAction';
import { FaSpinner } from 'react-icons/fa';
import { setAlert } from '../../../Redux/Actions/AlertActions';

const PayoutRequestModal = (props) => {
    const [filter, setFilter] = useState('');
    const [selectBank, setSelectBank] = useState('Select Bank');
    const [bankDetails, setbankDetails] = useState([])
    const [input, setInput] = useState("")
    const [loader, setLoader] = useState({
        fetch: false,
        confirm: false
    })

    const dispatch = useDispatch()


    const fetchBankData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "method": filter
        }
       
        dispatch(fetchBankDataAction(formData, setbankDetails, setLoader, loader))
    }
    const handleChange = (e) => {
        const { value } = e.target;

        setInput(value);


    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            confirm:true
        })
        const numericInput = parseFloat(input);

        if (isNaN(numericInput) || numericInput < 100) {
            dispatch(setAlert("The minimum withdraw amount is ₹100", "danger"))
            setLoader({
                ...loader,
                confirm:false
            })

            return;
        } else if (numericInput > props.avaiable) {
            dispatch(setAlert(`The maximum withdraw amount is ${props.avaiable} ₹`, "danger"))
            setLoader({
                ...loader,
                confirm:false
            })

            return;
        } else
            if (filter !== "1" && filter !== "2") {
                dispatch(setAlert("Please select a valid withdrawal method", "danger"))
                setLoader({
                    ...loader,
                    confirm:false
                })

                return;
            }
            else {

                let formData = {
                    "withdrawn_amount": input,
                    "withdrawn_mode": filter
                }
               
                dispatch(payOutRequestAction(formData,setLoader,loader,props.handleClose))
            }

    }
    useEffect(() => {
        if(filter){
            fetchBankData()
        }
       
    }, [filter])

    const numericInput = parseFloat(input) || 0;
    const remainingBalance = Math.max(props.avaiable - numericInput, 0);
    return (
        <>
            <Modal className="theme-modal Payout_request_wrap" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className="Payout_request_modal">
                        <div className="Payout_request_img ">
                            <img src={CurrencyDollar} alt="" />
                        </div>
                        <h3 className="text-center">Request For Payout </h3>
                        <p className="text-center pt-2">The weekly billing period begins Monday at 00:00 midnight and ends Sunday at 23:59 UTC. Because we’ve freelancers and clients from all across globe.</p>
                        <div className="modal-card mt-4 mb-5 bg-transparent">
                            <form onSubmit={handleSubmit}>
                                <div className="Payout_request_modal">
                                    <div className="account-block mt-2">
                                        <h6>Withdrawal Details</h6>
                                        <ul>
                                            <li>
                                                <p>Available Balance</p>
                                                <span>₹{props?.avaiable}</span>
                                            </li>
                                            <li>
                                                <p>Withdraw Amount</p>
                                                <input className='form-control w-50' type='number'
                                                    name="input" value={input}
                                                    onChange={handleChange}
                                                    min="100"
                                                    placeholder="Enter amount" />
                                              
                                            </li>
                                            <li>
                                                <p>Withdrawal Method</p>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic" className='border py-1 px-3 border-muted'>
                                                        {filter === "1" ? "UPI" : "Bank"}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className='py-0' onClick={() => setFilter("1")}>UPI</Dropdown.Item>
                                                        <Dropdown.Item className='py-0' onClick={() => setFilter("2")}>Bank</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                            </li>
                                            {
                                                filter === "1" ?
                                                    <li>
                                                        <ul className='w-100 mt-0'>
                                                            <li>
                                                                <p>UPI ID:</p>
                                                                <span>{bankDetails?.bank_details?.upi}</span>
                                                            </li>
                                                            <li>
                                                                <p>UPI Name</p>
                                                                <span>{bankDetails?.bank_details?.upi_name}</span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : ""
                                            }
                                            {
                                                filter === "2" ?
                                                    <li>
                                                        <ul className='w-100 mt-0'>
                                                            <li>
                                                                <p>Bank Name</p>
                                                                <span>{bankDetails?.bank_details?.bank_name}</span>
                                                            </li>
                                                            <li>
                                                                <p>IFSC Code</p>
                                                                <span>{bankDetails?.bank_details?.ifsc_code}</span>
                                                            </li>
                                                            <li>
                                                                <p>Account Holder Name</p>
                                                                <span>{bankDetails?.bank_details?.account_holder_name}</span>
                                                            </li>
                                                            <li>
                                                                <p>Account Number</p>
                                                                <span>{bankDetails?.bank_details?.account_no}</span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : ""}


                                            <hr className='bottom_line' style={{ opacity: "0.1", color: "#000000", borderWidth: "2px" }} />
                                            <li>
                                                <p>Remaining Balance</p>
                                                <span>₹{remainingBalance.toFixed(2)}</span>
                                                <div className="payout_img">
                                                    {/* {/ <img src={edit} alt="" />  /} */}
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="application_btn text-center mt-5 mb-3">
                                    <button className='theme-btn' type="submit">Confirm Request {loader.confirm ? <FaSpinner className="spin ms-1" /> : null}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default PayoutRequestModal
